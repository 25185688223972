<template>
	<modal
		v-model="isOpen"
		width="650px"
		:title="$t('ios-enable-notification')"
		type="question"
		:cancel-text="$t('cancel')"
		:confirm-text="$t('activate')"
		@cancel="isOpen = false"
		@confirm="triggerIOSNotification"
	>
		<v-card-text class="px-4 pt-0 text-subtitle-1">
			{{ $t("ios-audio-notification") }}
		</v-card-text>
	</modal>
</template>

<script>
import Modal from "@/components/common/Modal";
import { playAudio } from "@/composables/comms";
import { mapFields } from "vuex-map-fields";

export default {
	name: "IosNotificationDialog",
	components: { Modal },

	data() {
		return {
			isOpen: false
		};
	},

	computed: {
		...mapFields("conversation", ["audioNotificationsEnabled"])
	},

	mounted() {
		setTimeout(() => {
			const preventedRoutes = [
				"/onboarding",
				"/login",
				"/signup",
				"/forgot-password",
				"/reset-password"
			];

			if (!this.audioNotificationsEnabled) {
				const isIos =
					/iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

				this.isOpen = !preventedRoutes.includes(this.$route.path) && isIos;
			}
		}, 1000);
	},

	methods: {
		triggerIOSNotification() {
			this.audioNotificationsEnabled = true;
			this.isOpen = false;
			playAudio();
		}
	}
};
</script>
