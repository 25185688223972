import {
	CONNECTED,
	CONNECTING,
	CONNECTION_FAILED,
	DISCONNECTED,
	DISCONNECTING,
	INITIALIZATION_FAILED,
	INITIALIZING,
	RECONNECTING,
	desktopNotification,
	playAudio,
	toggleState
} from "./comms";

import { Client as ConversationsClient } from "@twilio/conversations";
import Vue from "vue";
import axios from "axios";
import { isEmpty } from "lodash";
import router from "../router";
import store from "../store";
import i18n from "../i18n";
import { getAuthType } from "./mail";

let conversationsClient = null;

let conversationEvents = false;

const useConversation = () => {
	const authenticate = async (userGroupId) => {
		const { data: token } = await axios.post("conversations/tokens", {
			userGroup: userGroupId
		});

		if (token?.migrating){
			setTimeout(async () => authenticate(userGroupId), 3000);
		} else {
			await conversationTokenAquired(token);
		}
	};

	const refreshToken = async () => {
		const { data: token } = await axios.post("conversations/tokens", {
			userGroup: store.getters["conversation/currentParticipant"]?.identity
		});

		conversationsClient.updateToken(token.jwt);
	};

	const onConversationJoined = async (conversation) => {
		await conversationJoined(conversation, true);
	};

	const onConversationUpdated = (updatePayload) => {
		const { updateReasons, conversation } = updatePayload;

		if (!updateReasons.includes("attributes")) return;

		const targetConversation = store.getters["conversation/allConversations"]?.find((target) => {
			return (
				target.sid === conversation.sid &&
				conversation.attributes.hasUnreadSurveys &&
				!conversation.attributes.hasActiveFlow
			);
		});

		if (
			targetConversation &&
			!conversation.attributes.hasIncompleteFollowup &&
			conversation.attributes.hasNotification
		) {
			playAudio();
			desktopNotification();
		}
	};

	const onConversationLeft = (conversation) => {
		store.commit("conversation/removeConversation", conversation);
	};

	const conversationJoined = async (conversation, isNew) => {
		conversation.channelState.lastMessage = conversation.lastMessage || {
			dateCreated: null,
			index: undefined
		};

		Vue.set(conversation, "active", false);

		if (
			isNew &&
			store.commit("conversation/addSingleConversation", conversation) && // return false if already inserted
			conversation.lastMessage?.index >= 0
		) {
			playAudio();
			desktopNotification();

			if (store.getters["conversation/filteredConversations"].length <= 1) {
				store.dispatch("conversation/displayConversation", "first");
			}
		}
	};

	const messageAdded = (message) => {
		if (message.attributes.isFlowMessage) return;

		if (
			message.state.author !== store.getters["conversation/currentParticipant"].identity &&
			message.conversation.sid === store.getters["conversation/singleConversation"]?.sid
		) {
			const isEmailMessage = message.state.attributes?.type === "email" && message.state.attributes?.fromClient;
			const isPhoneCallMessage =
				message.state?.attributes?.isCall && message.state?.attributes?.direction === "inbound";
			const isTextMessage = message.state?.type === "text" && isEmpty(message.state.attributes);

			if (isEmailMessage || isPhoneCallMessage || isTextMessage) {
				playAudio();
				desktopNotification();
			}
		}

		if (
			router.currentRoute.name == "conversations" &&
			message.conversation.sid === store.getters["conversation/singleConversation"].sid
		) {
			store.commit("conversation/addSingleMessage", message);
			store.commit("conversation/setNewMessage", true);
		} else {
			if (message.state.author === store.getters["conversation/currentParticipant"].identity) {
				return;
			}

			const targetConversation = store.getters["conversation/allConversations"]?.find(
				(conversation) => conversation.sid === message.conversation.sid
			);

			if (!targetConversation) return;

			playAudio();
			desktopNotification();
		}

		const currentPage = store.getters["conversation/currentPage"][0];
		const isInboundMessage = store.getters["conversation/isInboundMessage"](message);
		const toPage = isInboundMessage ? currentPage : 1;

		store.commit("conversation/setCurrentPage", { page: toPage, selectedTab: 0 });
	};

	const loadAllConversations = async () => {
		const fetchNextPage = async (currentPaginator) => {
			if (!currentPaginator.hasNextPage) return;

			const newPaginator = await currentPaginator.nextPage();

			conversationsBuffer.push(...newPaginator.items);
			await fetchNextPage(newPaginator);
		};

		var conversationsBuffer = [];
		const initialPaginator = await conversationsClient.getSubscribedConversations();

		conversationsBuffer.push(...initialPaginator.items);
		await fetchNextPage(initialPaginator);// load first page

		return conversationsBuffer;
	};

	const connectionStateChanged = async (connectionState) => {
		switch (connectionState) {
			case CONNECTING:
				toggleState(CONNECTING);
				break;
			case CONNECTED:
				try {
					await loadExistingConversations();
					toggleState(CONNECTED);
					loadConversationEvents();
				} catch (err) {
					toggleState(INITIALIZATION_FAILED);
				}
				break;
			case DISCONNECTING:
			// Prevents "Connexion interompue" when switching groups or updating clients
			case RECONNECTING:
				toggleState(RECONNECTING);
				break;
			case DISCONNECTED:
				if (store.getters["conversation/connectionState"] !== RECONNECTING) {
					toggleState(DISCONNECTED);
				}
				break;
		}
	};

	const loadExistingConversations = async() => {
		const conversations = await loadAllConversations();

		store.commit("conversation/setAllConversation", conversations);
		await Promise.all(conversations?.map((conversation) => conversationJoined(conversation)));
		await store.dispatch("conversation/displayConversation", "first");
	};

	const loadConversationEvents = () => {
		if (conversationEvents){
			return;
		}

		try {
			conversationsClient.on("conversationJoined", onConversationJoined);
			conversationsClient.on("conversationUpdated", onConversationUpdated);
			conversationsClient.on("conversationLeft", onConversationLeft);

			conversationsClient.on("messageAdded", messageAdded);
			conversationsClient.on("tokenAboutToExpire", refreshToken);
			conversationsClient.on("tokenExpired", refreshToken);
			conversationEvents = true;
		} catch (err){
			console.error("Error while adding converstion events ", err);
			conversationEvents = false;
			throw err;
		}
	};

	const getEmailAuthType = async (email) => {
		if (email) {
			try {
				return await getAuthType(email);
			} catch (error) {
				console.error("Error while getting auth type", error);
			}
		}
	};

	const conversationTokenAquired = async(conversationToken) => {
		if (conversationToken.identity){

			if (conversationToken.email) {
				try {

					const authType = await getEmailAuthType(conversationToken.email);

					conversationToken = {
						...conversationToken,
						emailProvider: authType?.type,
						valid: authType?.valid
					};
				} catch (error) {
					console.error("Error while getting auth type", error);
				}
			}

			store.commit("conversation/setCurrentParticipant", conversationToken);
		}

		initialize(conversationToken);
	};

	const bootstrap = async (userGroupIdentity) => {
		try {
			initialize();
			await authenticate(userGroupIdentity);
		} catch (err) {
			console.log(err);
			toggleState(INITIALIZATION_FAILED);
		}
	};

	const initialize = async(conversationToken) => {
		try {
			toggleState(INITIALIZING);
			cleanup();

			if (conversationToken?.jwt){
				conversationsClient = await ConversationsClient.create(conversationToken.jwt);

				conversationsClient.on("tokenAboutToExpire", refreshToken);
				conversationsClient.on("tokenExpired", refreshToken);
				conversationsClient.on("connectionStateChanged", connectionStateChanged);
				conversationsClient.on("connectionError", async (error) => {
					if (!error.terminal) return;
					console.error(error);
					toggleState(CONNECTION_FAILED);
				});
			}
		} catch (error) {
			toggleState(INITIALIZATION_FAILED);
			console.error(error);
			store.commit("alerts/add", {
				type: "error",
				message: i18n.t("error.twilio-client"),
				timeout: true
			});
		}
	};

	const cleanup = async () => {
		if (!conversationsClient) return;

		try {
			conversationsClient.removeListener("tokenAboutToExpire", refreshToken);
			conversationsClient.removeListener("tokenExpired", refreshToken);

			conversationsClient.removeListener("conversationJoined", onConversationJoined);
			conversationsClient.removeListener("conversationUpdated", onConversationUpdated);
			conversationsClient.removeListener("conversationLeft", onConversationLeft);

			conversationsClient.removeListener("messageAdded", messageAdded);

			await conversationsClient.shutdown();

			conversationsClient.removeListener("connectionStateChanged", connectionStateChanged);
		} catch (err) {
			console.error("Error occured while cleaning up conversation objects", err);
			throw new err;
		} finally {
			conversationsClient = null;
			conversationEvents = false;
		}
	};

	return {
		bootstrap,
		toggleState,
		cleanup
	};
};

export default useConversation;
