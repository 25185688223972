<template>
	<modal
		v-model="isOpen"
		width="650px"
		:title="dialogTitle"
		:cancel-text="$t('dont-ask-again')"
		:confirm-text="$t('connect-page')"
		:cancel-loading="isDisconnecting"
		@cancel="cancel"
		@confirm="confirm"
	>
		<v-card-text class="px-4 pt-0 text-subtitle-1 pb-16">
			<div class="pb-2">
				<span>{{ $t("email-colon") }}</span>
				<span class="font-weight-bold">{{ email }}</span>
			</div>
			<div>
				{{ dialogContent }}
			</div>
		</v-card-text>
	</modal>
</template>

<script>
import Modal from "@/components/common/Modal";
import { disconnectEmailProvider } from "@/composables/mail";
import { PROVIDER_NAME_MAP } from "@/common/const";
import { mapGetters } from "vuex";
import { mapFields } from "vuex-map-fields";

export default {
	name: "EmailDisconnectedDialog",
	components: { Modal },

	data() {
		return {
			isOpen: false,
			isDialogHasBeenShown: false,
			checkedEmails: {},
			isDisconnecting: false
		};
	},

	computed: {
		...mapFields("conversation", ["currentParticipant"]),
		...mapGetters("login", ["connected"]),

		dialogContent(){
			return this.$t("connection-expired");
		},

		email() {
			return this.currentParticipant?.email;

		},

		dialogTitle(){
			const provider = PROVIDER_NAME_MAP[this.currentParticipant?.emailProvider];

			return this.$t("reconnect-provider", { provider });
		}
	},

	watch: {
		currentParticipant() {
			const isThisEmailChecked = this.checkedEmails[this.currentParticipant?.email];

			if (this.currentParticipant?.emailProvider && !this.currentParticipant?.valid && !isThisEmailChecked) {
				this.isOpen = true;
				this.checkedEmails[this.currentParticipant?.email] = true;
			}
		},

		connected() {
			if (!this.connected) {
				this.checkedEmails = {};
			}
		}
	},

	methods: {
		async cancel(payload) {
			if (payload?.isClosing) {
				this.isDialogHasBeenShown = true;
			} else if (this.currentParticipant?.email) {
				try {
					this.isDisconnecting = true;
					await disconnectEmailProvider(this.currentParticipant?.email);
					this.$store.commit("conversation/setCurrentParticipant", {
						...this.currentParticipant,
						emailProvider: null,
						valid: null
					});
				} catch (err) {
					this.$store.commit("alerts/add", {
						type: "error",
						message: this.$t("error.logout"),
						timeout: true
					});
				} finally {
					this.isDisconnecting = false;
				}
			}
			this.isOpen = false;
		},

		confirm() {
			this.$router.push("/configuration?configTab=1&tab=1");
			this.isOpen = false;
		}
	}
};
</script>

