<template>
<svg
width="32"
height="32"
viewBox="0 0 32 32"
fill="none"
xmlns="http://www.w3.org/2000/svg"
>
<path
d="M17.333 26.6657C17.2065 26.6668 17.0645 26.6668 16.9024 26.6668H9.59555C8.105 26.6668 7.35861 26.6668 6.78874 26.3765C6.28697 26.1208 5.87932 25.7124 5.62366 25.2107C5.33301 24.6402 5.33301 23.8939 5.33301 22.4004V9.60042C5.33301 8.10695 5.33301 7.35966 5.62366 6.78923C5.87932 6.28746 6.28697 5.87981 6.78874 5.62415C7.35917 5.3335 8.10646 5.3335 9.59993 5.3335H22.3999C23.8934 5.3335 24.6391 5.3335 25.2095 5.62415C25.7113 5.87981 26.1203 6.28746 26.376 6.78923C26.6663 7.3591 26.6663 8.10549 26.6663 9.59604V16.8998C26.6663 17.0632 26.6663 17.2062 26.6651 17.3335M17.333 26.6657C17.7139 26.6623 17.9548 26.6485 18.1848 26.5933C18.4569 26.5279 18.7168 26.4198 18.9554 26.2736C19.2245 26.1086 19.4551 25.8787 19.9163 25.4175L25.417 19.9168C25.8782 19.4556 26.1075 19.225 26.2724 18.9559C26.4186 18.7173 26.5268 18.4567 26.5921 18.1846C26.6473 17.9547 26.6615 17.7141 26.6651 17.3335M17.333 26.6657V19.467C17.333 18.7202 17.333 18.3466 17.4783 18.0614C17.6062 17.8105 17.81 17.6067 18.0609 17.4788C18.3461 17.3335 18.7191 17.3335 19.4658 17.3335H26.6651"
stroke="currentColor"
stroke-opacity="0.8"
stroke-width="2"
stroke-linecap="round"
stroke-linejoin="round"
/>
</svg>
</template>
<script>
export default {
	name:"NotesIcon",

	props:{
		color:{
			type:String,
			default:"#000"
		}
	}
};
</script>