<template>
	<div>
		<div v-if="devMode">
			<div v-if="connected" class="text-center">
				<v-menu
					v-model="devPopup"
					:close-on-content-click="false"
					:nudge-width="250"
					offset-y
				>
					<template #activator="{ on: menu, attrs }">
						<v-tooltip left>
							<template #activator="{ on: tooltip }">
								<v-btn
									elevation="2"
									fab
									fixed
									bottom
									right
									x-small
									color="warning"
									v-bind="attrs"
									class="mb-5 mr-5"
									v-on="{...tooltip, ...menu}"
								>
									<v-icon>mdi-flag</v-icon>
								</v-btn>
							</template>
							<span>{{ $t(env) }}</span>
						</v-tooltip>
					</template>

					<v-card>
						<v-card-title>{{ currentMode }}</v-card-title>

						<v-card-text>
							<v-row>
								<v-col cols="12" class="d-flex flex-column">
									<v-btn
										:loading="syncing"
										color="primary"
										class="white--text"
										@click="syncConfiguration"
									>
										<v-icon
											left
											dark
										>
											mdi-server-network
										</v-icon>
										Redirect webhooks to current env
									</v-btn>

									<v-btn
										color="secondary"
										class="white--text mt-4"
										:href="REPOSITORY_URL"
										target="_blank"
									>
										<v-icon
											left
											dark
										>
											mdi-github
										</v-icon>
										GitHub repository
									</v-btn>
								</v-col>
							</v-row>
						</v-card-text>

						<v-card-actions>
							<v-spacer />

							<v-btn
								text
								@click="devPopup = false"
							>
								Close
							</v-btn>
						</v-card-actions>
					</v-card>
				</v-menu>
			</div>

			<div v-else>
				<v-tooltip left>
					<template #activator="{ on }">
						<v-btn
							elevation="2"
							fab
							fixed
							bottom
							right
							x-small
							color="warning"
							class="env"
							:href="REPOSITORY_URL"
							target="_blank"
							v-on="on"
						>
							<v-icon>mdi-flag</v-icon>
						</v-btn>
					</template>
					<span>{{ $t(env) }}</span>
				</v-tooltip>
			</div>
		</div>
	</div>
</template>

<script>
import { mapMutations, mapGetters } from "vuex";
import { REPOSITORY_URL } from "@/common/const";

export default {
	name: "Env",

	data() {
		return {
			devPopup: false,
			syncing: false,
			REPOSITORY_URL
		};
	},

	computed: {
		...mapGetters("login", ["profile", "connected"]),

		devMode() {
			return ["development", "staging"].includes(process.env.NODE_ENV);
		},

		env() {
			return process.env.NODE_ENV;
		},

		currentMode() {
			const modes = new Map([
				["development", "Development mode"],
				["staging", "Staging mode"]
			]);

			return modes.get(process.env.NODE_ENV);
		}
	},

	methods: {
		...mapMutations("alerts", { showAlert: "add" }),

		async syncConfiguration() {
			try {
				this.syncing = true;
				await this.axios.post("conversations/synchronize");
				await this.axios.post("conversations/flows/synchronize");
				await this.axios.post("voice/v1/synchronize");

				this.showAlert({
					type: "success",
					message: this.$t("sync-success"),
					timeout: true
				});
			} catch (err) {
				this.showAlert({
					type: "error",
					message: this.$t("errors.sync"),
					timeout: true
				});
			} finally {
				this.syncing = false;
			}
		}
	}
};
</script>

<style scoped lang="scss">
	.env {
		z-index: 6;
	}
</style>
