<template>
	<modal-support
		v-model="showDialog"
		:width="$vuetify.breakpoint.xl ? '75%' : '80%'"
		:title="$t('contact-us')"
		:sub-title=" $t('support-message')"
		phone-information="+1 (361) 944 7935"
		email-information="hello@inservio.app"
		locale-information="United States"

		type="question"
		:is-cta-disable="isCtaDisable"
		:cancel-text="$t('cancel')"
		:confirm-text="$t('send')"
		@cancel="closeDialog"
		@confirm="sendMessage"
	>
		<v-row no-gutters>
			<support-form
				v-model="validForm"
				:form="form"
				:support-dialog="supportDialog"
				@updateValidation="updateValidation"
				@onDialogClose="supportDialog = false"
			/>
		</v-row>
	</modal-support>
</template>

<script>
import { mapGetters } from "vuex";
import SupportForm from "./SupportForm";
import ModalSupport  from "@/components/common/ModalSupport.vue";

export default {
	name: "SupportDialog",
	components: { SupportForm, ModalSupport },

	props: {
		value: {
			type: Boolean,
			default: false
		},

		fullPath: {
			type: String,
			default: undefined
		}
	},

	data() {
		return {
			validForm: false,
			loading: false,
			form: {
				name: undefined,
				email: undefined,
				url: undefined,
				screenshot: undefined,
				description: undefined
			},
			supportDialog: false
		};
	},

	computed: {
		...mapGetters("login", ["profile"]),

		showDialog: {
			get() {
				return this.value;
			},

			set(value) {
				this.$emit("input", value);
			}
		},

		isCtaDisable() {
			return {
				cancel: this.loading,
				confirm: !this.validForm
			};
		}
	},

	watch: {
		fullPath() {
			this.form.url = "";
		}
	},

	async mounted() {
		this.form.name = `${this.profile.name.first} ${this.profile.name.last}`;
		this.form.email = this.profile.email;
	},

	methods: {
		async sendMessage() {
			this.loading = true;

			try {
				await this.axios.post("email/support", {
					name: this.form.name,
					email: this.form.email,
					pageLink: this.form.url,
					screenshotFile: this.form.screenshot,
					description: this.form.description
				});

				this.$store.commit("alerts/add", {
					type: "success",
					message: this.$t("message-sent"),
					timeout: true
				});

				this.closeDialog();
			} catch (err) {
				this.$store.commit("alerts/add", {
					type: "error",
					message: this.$t("error.send-support-request"),
					timeout: true
				});
			} finally {
				this.loading = false;
			}
		},

		closeDialog() {
			this.supportDialog = true;
			this.form = {
				...this.form,
				screenshot: undefined,
				description: undefined
			};
			this.$emit("closeDialog");
		},

		updateValidation(isFormValid) {
			this.validForm = isFormValid;
		}
	}
};
</script>

<style scoped>
.content-height {
	height: 45vh;
	overflow-y: auto !important;
}
</style>
