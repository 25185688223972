<template>
	<v-app>
		<v-banner
			v-if="status.banner === true"
			lines="one"
			icon="mdi-wrench"
			:color="statusColorEnum[statusEnum.indexOf(status.status)]"
			sticky
			elevation="10"
			rounded
			class="z-fixed"
>
			<span class="font-weight-bold my-0 py-0">
				{{ status.bannerText }}
			</span>

			<template #actions="{ dismiss }">
				<v-btn v-if="isDevops || status.dismissable" @click="dismiss">
					{{ $t("close") }}
				</v-btn>
			</template>
		</v-banner>
		<alerts />
		<env />
		<import-contacts-progress />
		<navigation-menu v-if="showNav" />
		<call-draggable />
		<modal
v-if="openDisconnectDialog"
v-model="openDisconnectDialog"
width="600"
:confirm-text="$t('i-understand')"
			:persistent="true"
:title="$t('force-disconnect')"
:type="'question'"
@confirm="handleSessionDisconnected"
>
			<v-card-text class="text-body-1">
				{{ $t("disconnect-info") }}
			</v-card-text>
			<v-card-text class="text-body-1 font-weight-bold">
				{{ $t("disconnect-hint") }}
			</v-card-text>
			<v-divider class="mb-4" />
		</modal>
		<microphone-guide-dialog />
		<ios-notification-dialog v-if="connected" />
		<email-disconnected-dialog v-if="connected" />

		<v-overlay v-if="!isDevops && nav && status.overlay" absolute>
			<v-row align-content="center" justify="center">
				<v-col class="text-subtitle-1 text-center font-weight-bold" cols="9">
					{{ $t("loadingText") }}
				</v-col>
				<v-col cols="6">
					<v-progress-linear indeterminate rounded height="12" />
				</v-col>
			</v-row>
		</v-overlay>

		<v-main v-if="!nav || isDevops || !status.overlay">
			<router-view />
		</v-main>
	</v-app>
</template>

<script>
import Vue from "vue";
import { bus } from "@/main.js";
import { mapFields } from "vuex-map-fields";
import { mapActions, mapState, mapGetters, mapMutations } from "vuex";
import NavigationMenu from "./components/navigation/NavigationMenu";
import { handleLogoutEvent } from "./composables/auth";
import useConversation from "@/composables/conversation";
import { ALERT_HIDDEN, ALERT_SHOWN, ALERT_BLOCKED, CALL_STATUS } from "@/common/const";
import messaging from "./composables/messaging";
import useVoice from "@/composables/voice";
import CallDraggable from "./components/conversations/call-dialog/CallDraggable.vue";
import MicrophoneGuideDialog from "./components/conversations/call-dialog/MicrophoneGuideDialog.vue";
import IosNotificationDialog from "./components/conversations/chat-box/IosNotificationDialog.vue";
import EmailDisconnectedDialog from "./components/conversations/chat-box/EmailDisconnectedDialog.vue";
import ImportContactsProgress from "./components/configuration/my-contact/ImportContactsProgress.vue";
import Modal from "./components/common/Modal.vue";
import vuetify from "./plugins/vuetify";

export const API_URL = process.env.VUE_APP_INSERVIO_API_URL;
export const FRENCH_PHONE_NUMBER = process.env.VUE_APP_FRENCH_PHONE_NUMBER;
export const VUE_APP_INSERVIO_PRIVACY_POLICY_URL =
	process.env.VUE_APP_INSERVIO_PRIVACY_POLICY_URL;
export const VUE_APP_INSERVIO_TERMS_OF_USE_URL =
	process.env.VUE_APP_INSERVIO_TERMS_OF_USE_URL;
export const MICROSITE_URL = process.env.VUE_APP_MICROSITE_URL;
export const LOGIN_PATH = "login";
export const AUDIO_DEVICE_NAME = "audioinput";

const conversationsHook = useConversation();

Vue.mixin({
	computed: {
		...mapFields("conversation", ["showNotificationsAlert"]),

		displayNotificationSettings() {
			return (isAlert) => {
				const isIos =
					/Mac|iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

				if (isAlert) {
					return (
						!isIos &&
						this.showNotificationsAlert === ALERT_SHOWN &&
						this.$vuetify.breakpoint.mdAndUp
					);
				}

				return !isIos && this.$vuetify.breakpoint.mdAndUp;
			};
		}
	}
});

const MIN_WIDTH = 1024;

export default {
	name: "App",

	components: {
		NavigationMenu,
		CallDraggable,
		MicrophoneGuideDialog,
		ImportContactsProgress,
		IosNotificationDialog,
		EmailDisconnectedDialog,
		Modal
	},

	data() {
		return {
			status: {
				banner: undefined,
				bannerText: undefined,
				dismissable: undefined,
				overlay: undefined,
				status: undefined
			},
			statusColorEnum: ["success", "info", "warning", "warning", "error"],
			statusEnum: [
				"operational",
				"under_maintenance",
				"degraded_performance",
				"partial_outage",
				"major_outage"
			],
			timer: null,
			openDisconnectDialog: false
		};
	},

	metaInfo() {
		if (process.env.NODE_ENV !== "staging") return;

		return {
			meta: [
				{
					name: "google-site-verification",
					content: "U3DXAYfupUBb8_5KI0A3JQIRdgCX0R7xeIeJ5jBa59E"
				}
			]
		};
	},

	computed: {
		...mapState("ui", ["nav", "menu", "fullScreen"]),
		...mapGetters("login", ["profile", "connected", "isDevops"]),
		...mapGetters("clinic", ["clinicData", "country"]),
		...mapGetters("conversation", ["hasPendingNotifications", "currentGroup"]),

		...mapGetters("voice", [
			"hasIncomingCall",
			"isOnCall",
			"isMicroOn",
			"activeCallConvoSid",
			"activeCall"
		]),

		...mapFields("conversation", [
			"browserNotificationsDisabled",
			"desktopNotificationsEnabled",
			"showNotificationsAlert"
		]),

		showNav() {

		  return this.$vuetify.breakpoint.lgAndUp || this.nav;

		}

	},

	watch: {
		hasPendingNotifications: {
			immediate: true,

			handler(hasNotifications) {
				this.handleNotifications(
					hasNotifications,
					5000,
					2000,
					"inservio 💬",
					"inservio",
					"New message"
				);
			}
		},

		profile: {
			immediate: true,

			async handler(val) {
				if (!this.connected) return;

				if (!this.country) {
					await this.$store.dispatch("clinic/getClinicData");
				}

				if (this.country == "FR") {
					messaging.bootstrap();
				} else {
					conversationsHook.bootstrap(val?.defaultGroup || this.currentGroup);
					useVoice.bootstrap();
				}

				this.$i18n.locale = "en-CA";
				this.$root.$i18n.locale = this.$i18n.locale;
			}
		},

		hasIncomingCall: {
			immediate: true,

			handler(hasIncomingCall) {
				this.handleNotifications(
					hasIncomingCall,
					5000,
					2000,
					"inservio 📲",
					"inservio",
					"New message"
				);
			}
		},

		isOnCall: {
			immediate: true,

			async handler(isOnCall) {
				if (isOnCall) {
					this.enableMicrophone();
					this.setCurrentCallDetails();
				} else {
					this.setIsMicroOn(true);
					this.disableMicrophone();
					this.setActiveCallConvoSid(undefined);
				}
			}
		},

		activeCall: {
			immediate: true,

			handler(activeCall) {
				if (
					activeCall?.callInstance.status() === CALL_STATUS.PENDING &&
					!this.connected
				) {
					activeCall.reject();
				}
			}
		}
	},

	async created() {
		window.addEventListener("beforeunload", async () => {
			if (this.country == "FR") {
				return messaging.cleanup();
			}

			useVoice.cleanup();

			this.setCurrentParticipant(null);
			await conversationsHook.cleanup();
		});

		if (this.showNotificationsAlert !== ALERT_BLOCKED) {
			this.showNotificationsAlert = ["denied", "default"].includes(
				Notification.permission
			)
				? ALERT_SHOWN
				: ALERT_HIDDEN;
		}

		if (Notification.permission === "denied")
			this.browserNotificationsDisabled = true;

		if (this.browserNotificationsDisabled) {
			if (Notification.permission === "granted") {
				this.browserNotificationsDisabled = false;
				this.desktopNotificationsEnabled = true;
				this.showNotificationsAlert = ALERT_HIDDEN;
			} else {
				this.desktopNotificationsEnabled = false;
			}
		}

		addEventListener("mouseup", () => this.$store.dispatch("login/activity"));
		addEventListener("keyup", () => this.$store.dispatch("login/activity"));
		addEventListener("storage", handleLogoutEvent);

		// Handler for single session events
		bus.$on("disconnected", () => {
			this.openDialog();
		});

		await this.getStatus();

		if (navigator.mediaDevices?.enumerateDevices()) {
			try {
				// List cameras and microphones.
				const devices = await navigator.mediaDevices.enumerateDevices();
				const mic = devices.filter((device) => device.kind === AUDIO_DEVICE_NAME);

				if (!mic.length) {
					this.$store.commit("alerts/add", {
						type: "error",
						message: this.$t("no-microphone"),
						timeout: false
					});
				}
			} catch (error) {
				// Silence error
				console.error(`Error occured while enumerating devices: ${error}`);
			}
		}

		window.setInterval(() => {
			this.getStatus();
		}, 120000);
	},

	mounted() {
		const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
		const currentPath = window.location.pathname;

		if (this.$vuetify.breakpoint.mdAndDown){
			this.$store.commit("ui/setNav", false);
		}
		this.nav === false;
		if (isMobile && currentPath.includes(LOGIN_PATH)) {
			const vw = Math.max(
				document.documentElement.clientWidth || 0,
				window.innerWidth || 0
			);

			if (vw < MIN_WIDTH) {
				alert(this.$t("small"));
			}
		}
	},

	methods: {
		...mapActions("login", ["logout"]),
		...mapMutations("conversation", ["setCurrentParticipant"]),

		...mapMutations("voice", [
			"setIsMicroOn",
			"enableMicrophone",
			"disableMicrophone",
			"setActiveCallConvoSid"
		]),

		...mapActions("voice", ["setCurrentCallDetails"]),

		async getStatus() {
			try {
				this.status = (await this.axios.get("status")).data;
			} catch (err) { }
		},

		handleNotifications(
			hasNotification,
			appearTime,
			clearTime,
			notificationTitle,
			title,
			message
		) {
			const setTitle = (title, clearTime) => {
				if (clearTime) {
					setTimeout(() => {
						document.title = title;
					}, clearTime);
				} else {
					document.title = title;
				}
			};

			document.title = hasNotification ? notificationTitle : title;

			if (hasNotification) {
				this.timer = setInterval(() => {
					if (hasNotification) {
						document.title = message;
						setTitle(notificationTitle, clearTime);
					}
				}, appearTime + clearTime);
			} else {
				if (this.timer) clearInterval(this.timer);
				setTitle(title, clearTime);
			}
		},

		openDialog() {
			this.openDisconnectDialog = true;
		},

		async handleSessionDisconnected() {
			this.openDisconnectDialog = false;
			this.$router.go(0);
		}
	}
};
</script>
<style scoped lang="scss">

</style>
