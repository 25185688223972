<template>
<svg width="40" height="32" viewBox="0 0 40 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M36 28H32V10.5L20 18L8 10.5V28H4V4H6.4L20 12.5L33.6 4H36V28ZM36 0H4C1.78 0 0 1.78 0 4V28C0 29.0609 0.421427 30.0783 1.17157 30.8284C1.92172 31.5786 2.93913 32 4 32H36C37.0609 32 38.0783 31.5786 38.8284 30.8284C39.5786 30.0783 40 29.0609 40 28V4C40 1.78 38.2 0 36 0Z" fill="currentColor"/>
</svg>

</template>
<script>
export default {
	name:"GmailIcon"
};
</script>