<template>
	<v-sheet width="100%">
		<span class="text-body-1 px-2">{{ $t("status-instruction") }}</span>

		<v-form
			v-model="validForm"
			class="text-center my-5"
		>
			<v-checkbox
				v-model="inputForm.enabled"
				data-cy="admin-modal-enabled-field"
				class="mt-0"
				:label="$t('status-enabled')"
				background-color="light-primary"
			/>

			<v-text-field
				v-model.number="inputForm.alertBeforeMins"
				data-cy="admin-modal-alert-before-mins-field"
				class="mt-0"
				outlined
				dense
				:label="$t('status-alert-before')"
				background-color="light-primary"
			/>

			<v-checkbox
				v-model="inputForm.banner"
				data-cy="admin-modal-banner-field"
				class="mt-0"
				:label="$t('status-banner')"
				background-color="light-primary"
			/>

			<v-text-field
				v-model="inputForm.bannerText"
				data-cy="admin-modal-banner-text-field"
				class="mt-0"
				outlined
				dense
				:label="$t('status-banner-text')"
				background-color="light-primary"
			/>

			<v-checkbox
				v-model="inputForm.dismissable"
				data-cy="admin-modal-dismissable-field"
				class="mt-0"
				:label="$t('status-dismissable')"
				background-color="light-primary"
			/>

			<v-text-field
				v-model.number="inputForm.durationMins"
				data-cy="admin-modal-duration-mins-field"
				class="mt-0"
				outlined
				dense
				:label="$t('status-duration')"
				background-color="light-primary"
			/>

			<v-text-field
				v-model="inputForm.nextMaintenance"
				data-cy="admin-modal-next-maintenance-field"
				type="datetime-local"
				class="mt-0"
				outlined
				dense
				:label="$t('status-next-maintenance')"
				background-color="light-primary"
			/>

			<v-checkbox
				v-model="inputForm.overlay"
				data-cy="admin-modal-overlay-field"
				class="mt-0"
				:label="$t('status-overlay')"
				background-color="light-primary"
			/>

			<v-checkbox
				v-model="inputForm.sourceTwilio"
				data-cy="admin-modal-source-twilio-field"
				class="mt-0"
				:label="$t('status-source-twilio')"
				background-color="light-primary"
			/>

			<v-select
				v-model="inputForm.status"
				:items="['operational', 'under_maintenance', 'degraded_performance', 'partial_outage', 'major_outage']"
				data-cy="admin-modal-status-field"
				class="mt-0"
				outlined
				dense
				:label="$t('status')"
				background-color="light-primary"
			/>
		</v-form>
	</v-sheet>
</template>

<script>
import { mapGetters } from "vuex";

export default {
	name: "AdminForm",

	props:{
		value : {
			type : Boolean,
			default : false
		},

		form : {
			type : Object,
			default : undefined
		},

		adminDialog : {
			type : Boolean,
			default : false
		}
	},

	data() {
		return {
			validForm: false,
			inputForm: {}
		};
	},

	computed: {
		...mapGetters("login", ["profile"])
	},

	watch: {
		form(updatedForm) {
			this.inputForm = updatedForm;
		},

		validForm (isFormValid) {
			this.$emit("updateValidation", isFormValid);
		},

		adminDialog(isCloseDialog) {
			if (isCloseDialog) {
				this.imgFileInput = undefined;
				this.$emit("onDialogClose");
			}
		}
	},

	mounted() {
		this.inputForm = this.form;
	}
};
</script>