<template>
<svg width="34" height="37" viewBox="0 0 34 37" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M25.472 4.94935H25.6387C30.241 4.94935 33.972 8.68031 33.972 13.2827V28.2827C33.972 32.8851 30.241 36.616 25.6387 36.616H8.97201C6.76187 36.616 4.64225 35.738 3.07945 34.1752C1.51665 32.6124 0.638672 30.4928 0.638672 28.2827V13.2827C0.638672 8.68031 4.36963 4.94935 8.97201 4.94935H9.13867V1.91602C9.13867 1.22566 9.69832 0.666016 10.3887 0.666016C11.079 0.666016 11.6387 1.22566 11.6387 1.91602V4.94935H22.972V1.91602C22.972 1.22566 23.5317 0.666016 24.222 0.666016C24.9124 0.666016 25.472 1.22566 25.472 1.91602V4.94935ZM9.80534 15.0993H24.8053C25.4957 15.0993 26.0553 14.5397 26.0553 13.8493C26.0553 13.159 25.4957 12.5993 24.8053 12.5993H9.80534C9.11498 12.5993 8.55534 13.159 8.55534 13.8493C8.55534 14.5397 9.11498 15.0993 9.80534 15.0993Z" fill="currentColor"/>
</svg>

</template>

<script>
export default {
	name: "CalendarIcon"
};
</script>

<style lang="scss" scoped>

</style>