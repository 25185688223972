<template>
	<svg
		width="29"
		height="29"
		viewBox="0 0 29 29"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M5.33333 7.99984L13.4768 14.1495L13.4796 14.1518C14.3838 14.8149 14.8362 15.1466 15.3317 15.2748C15.7696 15.3881 16.23 15.3881 16.668 15.2748C17.1639 15.1465 17.6176 14.8138 18.5234 14.1495C18.5234 14.1495 23.7468 10.1411 26.6667 7.99984M4 21.0668V10.9334C4 9.43996 4 8.69266 4.29065 8.12223C4.54631 7.62047 4.95396 7.21282 5.45573 6.95715C6.02616 6.6665 6.77345 6.6665 8.26693 6.6665H23.7336C25.2271 6.6665 25.9728 6.6665 26.5432 6.95715C27.045 7.21282 27.454 7.62047 27.7096 8.12223C28 8.69211 28 9.4385 28 10.9291V21.0713C28 22.5618 28 23.3071 27.7096 23.877C27.454 24.3788 27.045 24.7871 26.5432 25.0428C25.9733 25.3332 25.228 25.3332 23.7375 25.3332H8.26255C6.77199 25.3332 6.0256 25.3332 5.45573 25.0428C4.95396 24.7871 4.54631 24.3788 4.29065 23.877C4 23.3066 4 22.5602 4 21.0668Z"
			stroke="currentColor"
			stroke-opacity="0.8"
			stroke-width="2"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
	</svg>
</template>
<script>
export default {
	name:"EmailIcon",

	props:{
		color:{
			type:String,
			default:"#000"
		}
	}
};
</script>