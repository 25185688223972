
export default {
	namespaced: true,
	state: ()=>({
		documentList: [
			{
				id: "0",
				name: "document 1"
			},
			{
				id: "1",
				name: "document 2"
			}
		],
		activeDocId: 0,
		templateList: [
			"Standard Consultation"
		],
		appointmentList: [
			"30 April, 12:00pm - Standard Appointment (Consultation)"
		],
		files: [
			{
				id: "0",
				documentId: "0",
				check: false,
				template: "Standard Consultation",
				appointment: "30 April, 12:00pm - Standard Appointment (Consultation)"

			},
			{
				id: "1",
				documentId: "0",
				check: false,
				template: "Standard Consultation",
				appointment: "30 April, 12:00pm - Standard Appointment (Consultation)"

			},
			{
				id: "2",
				documentId: "1",
				check: false,
				template: "Standard Consultation",
				appointment: "30 April, 12:00pm - Standard Appointment (Consultation)"
			}
		]
	}),
	getters: {
		getAllDocuments: (state) => state.documentList,
		getAllTypes: (state) => state.types
	},
	mutations: {
		setActiveDoc(state, index) {
			state.activeDocId = index;
		},
		pushDocument(state, document) {
			state.documentList.push(document);
		},
		setUpdateDocument(state, { id, name, type }) {
			const updateDocument = state.documentList.find(document => document.id === id);

			updateDocument.name = name;
			updateDocument.type = type;
		},
		removeDocument(state, id) {
			state.documentList.map((document, index) => {
				if (document.id === id) {
					state.documentList.splice(index, 1);
				}
			});

		},
		pushFile(state, file) {
			state.files.push(file);
		},
		setUpdateFile(state, { id, progressReport, assessment, treatment  }) {
			const updateFile = state.files.find(file => file.id = id);

			updateFile.progressReport = progressReport;
			updateFile.assessment = assessment;
			updateFile.treatment = treatment;
		},
		removeFile(state, id) {
			state.files.map((file, index) => {
				if (file.id === id) {
					state.files.splice(index, 1);
				}
			});
		}

	},
	actions: {
		addDocument({ commit }, document) {
			commit("pushDocument", document);
		},
		updateDocument({ commit }, document) {
			commit("setUpdateDocument", document);
		},
		deleteDocument({ commit }, id) {
			commit("removeDocument", id);
		},
		addFile({ commit }, file) {
			commit("pushFile", file);
		},
		editFile({ commit }, file) {
			commit("setUpdateFile", file);
		},
		deleteFile({ commit }, id) {
			commit("removeFile", id);
		}
	}
};