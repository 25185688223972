import axios from "axios";
import { getField, updateField } from "vuex-map-fields";

const batchCreateContacts = async (data) => {
	const chunks = [];
	const CHUNK_SIZE = 10;

	for (let i = 0; i < data.length; i += CHUNK_SIZE) {
		chunks.push(data.slice(i, i + CHUNK_SIZE));
	}

	return await Promise.all(chunks.map(chunk => {
		return axios.post("clients/batch", chunk);
	}));
};

export default {
	namespaced: true,
	getters: {
		getField,
		isOpenImportContactsDialog(state) {
			return state.isOpenImportContactsDialog;
		},

		isSubmitting(state) {
			return state.isSubmitting;
		},

		allContacts(state) {
			return state.allContacts;
		}
	},

	mutations: {
		updateField,
		setIsOpenImportContactsDialog(state, value) {
			state.isOpenImportContactsDialog = value;
		},

		setIsSubmitting(state, value) {
			state.isSubmitting = value;
		},

		setAllContacts(state, allContacts) {
			state.allContacts = allContacts;
		}
	},

	actions: {
		async submitContacts({ commit }, data) {
			commit("setIsSubmitting", true);

			try {
				await batchCreateContacts(data);
			} catch (err) {
				throw err;
			} finally {
				commit("setIsSubmitting", false);
			}
		},

		async getAllContacts(withConversation) {
			try {
				const { data: allClients } = await axios.get(
					`/clients?withConversations=${withConversation}`
				);

				commit("setAllContacts", allClients);
			} catch (err) {
				throw err;
			}
		}
	},

	state: () => ({
		isOpenImportContactsDialog: false,
		isSubmitting: false,
		isDisplayContactForm: false,
		openContactDrawer: false,
		seeMoreAppointments: false,
		seeMoreNotes: false,
		seeMoreSurveys: false,
		toggleContactDrawer: true,
		selectedSurvey: undefined,
		allContacts: [],
		options: {
			sortBy: ["name.last"],
			sortDesc: [false]
		},
		selectedItems: []
	})
};
