<template>
	<svg
		width="28"
		height="28"
		viewBox="0 0 32 32"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M11.9993 22.6667H19.9993M11.9993 18.6667H19.9993M17.3332 4.00116C17.2058 4 17.0625 4 16.899 4H10.9329C9.43947 4 8.69218 4 8.12174 4.29065C7.61998 4.54631 7.21233 4.95396 6.95666 5.45573C6.66602 6.02616 6.66602 6.77345 6.66602 8.26693V23.7336C6.66602 25.2271 6.66602 25.9734 6.95667 26.5438C7.21233 27.0456 7.61998 27.454 8.12174 27.7096C8.69162 28 9.43802 28 10.9286 28L21.0701 28C22.5607 28 23.306 28 23.8759 27.7096C24.3776 27.454 24.7867 27.0456 25.0423 26.5438C25.3327 25.974 25.3327 25.2287 25.3327 23.7381V12.4342C25.3327 12.2707 25.3325 12.1274 25.3314 12M17.3332 4.00116C17.7138 4.00463 17.9536 4.01876 18.1836 4.07396C18.4557 4.13929 18.7165 4.24702 18.9551 4.39323C19.2241 4.55809 19.4551 4.78908 19.916 5.25L24.0833 9.41731C24.5445 9.87851 24.7738 10.1085 24.9388 10.3776C25.085 10.6162 25.1931 10.8763 25.2585 11.1484C25.3137 11.3784 25.3279 11.6194 25.3314 12M17.3332 4.00116L17.3327 7.73361C17.3327 9.22709 17.3327 9.97353 17.6233 10.544C17.879 11.0457 18.2866 11.454 18.7884 11.7096C19.3583 12 20.1047 12 21.5952 12H25.3314"
			stroke="#123873"
			stroke-width="2"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
	</svg>
</template>
<script>
export default {
	name:"FileDocumentIcon"
};
</script>