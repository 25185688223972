<template>
	<v-sheet rounded :class="{ 'pt-3': isCallDialogAttached }">
		<v-card-title class="text-h5 text-center font-weight-bold" style="display: flex; justify-content: center; align-items: center;">
			{{ callTitle }}
		</v-card-title>
		<v-card-subtitle v-if="forGroupLabel" class="font-weight-bold">
			{{ forGroupLabel }}justify-center
			<v-tooltip v-if="forGroupLabel" bottom min-width="350" max-width="350">
				<template #activator="{ on }">
					<v-icon v-if="forGroupLabel" color="primary" md v-on="on">
						mdi-information-slab-circle
					</v-icon>
				</template>
				<span>{{ $t("for-group-tooltip", { label: callDetails?.forGroupLabel }) }}</span>
			</v-tooltip>
		</v-card-subtitle>
		<v-card-subtitle v-if="fromGroupLabel" class="font-weight-bold">
			{{ fromGroupLabel }}
		</v-card-subtitle>
		<v-divider class="ml-4 mr-5 mt-n3" />
		<call-info />

		<call-actions @showDialpad="showDialpad = !showDialpad" @transfering="transfering = !transfering" />

		<dialpad v-if="isOnCall && showDialpad" class="mt-n4" />

		<v-divider v-if="!showDialpad && isCallDialogAttached" class="mt-n4" />
	</v-sheet>
</template>

<script>
import { mapGetters } from "vuex";
import CallInfo from "./CallInfo.vue";
import CallActions from "./CallActions.vue";
import Dialpad from "../Dialpad.vue";

export default {
	name: "CallDetails",

	components: {
		CallInfo,
		CallActions,
		Dialpad
	},

	data() {
		return {
			showDialpad: false,
			transfering: false
		};
	},
	
	computed: {
		...mapGetters("voice", [
			"isCallDialogAttached",
			"activeCall",
			"callDetails",
			"isOnCall"
		]),

		...mapGetters("conversation", ["currentParticipant"]),

		callTitle() {
			if (
				this.activeCall?.duration === "00:00" &&
				this.activeCall?.direction === "outbound"
			) {
				return this.$t("calling");
			}
			if (this.activeCall?.direction === "outbound") {
				return this.$t("outbound-call");
			} else if (this.activeCall?.direction === "inbound"){
				return this.$t("incoming-call");
			} else {
				return this.$t("transfered-call");
			}
		},

		forGroupLabel() {
			// Check if incoming call
			if (this.activeCall?.direction === "inbound") {
				// Check if data exists before display UI
				if (this.currentParticipant.identity && this.callDetails?.forGroupId) {
					return this.currentParticipant.identity === this.callDetails?.forGroupId ? null : this.$t("for-group", { label: this.callDetails?.forGroupLabel });
				}
			}
			return null;
		},

		fromGroupLabel() {
			if (this.callDetails?.isTransfer) {
				return this.$t("from-group", { label: this.callDetails?.fromGroupLabel });
			}
			return null;
		},

		showAttachIcon() {
			return (
				!this.isCallDialogAttached &&
				this.$route.path == "/conversations" &&
				!this.$vuetify.breakpoint.smAndDown
			);
		}

	},

	created() {
		this.$store.commit("voice/setCallDialogAttached", false);
	}
};
</script>

<style scoped>
.drag-icon {
	cursor: grab;
}
</style>
