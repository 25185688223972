import PhoneCall from "./phone-call";
import axios from "axios";
import store from "../../store";

class InboundCall extends PhoneCall {
	constructor(callInstance) {
		super(callInstance);

		this.direction = "inbound";
	}

	loadDetails = async () => {
		try {
			let res;

			try {
				res = await axios.get(`clients/${this.callInstance.parameters.From}`);
			} catch (error) {
				// Silence the error
			}
			const { from, fromGroup, fromGroupLabel, id, label, transfer } = await this.getData();

			if (transfer) {
				this.direction = "transfer";
			}
			this.callDetails = {
				...res?.data,
				phoneNumber: this.callInstance.parameters.From,
				callSid: this.callInstance.parameters.CallSid,
				forGroupId: id,
				forGroupLabel: label,
				from: from,
				fromGroup: fromGroup,
				fromGroupLabel: fromGroupLabel,
				isTransfer: transfer
			};
		} catch (err) {
			this.callDetails = {
				phoneNumber: this.callInstance.parameters.From,
				callSid: this.callInstance.parameters.CallSid
			};
		}

		store.commit("voice/setCallDetails", this.callDetails);
	};

	static from(callInstance) {
		return new InboundCall(callInstance);
	}
}

export default InboundCall;
