<template>
	<v-layout column class="py-8 mx-8">
		<v-card-actions class="mt-3">
			<v-row
				v-if="hasIncomingCall"
				no-gutters
				class="px-0 d-flex justify-space-around"
			>
				<v-tooltip top>
					<template #activator="{ on, attrs }">
						<v-btn
							fab
							color="success"
							class="button no-shadow"
							large
							v-bind="attrs"
							v-on="on"
							@click="handleAccept"
						>
							<v-icon color="white" large>
								mdi-phone
							</v-icon>
						</v-btn>
					</template>

					<span>{{ $t("answer") }}</span>
				</v-tooltip>

				<v-tooltip top>
					<template #activator="{ on, attrs }">
						<v-btn
							fab
							color="call-red"
							class="button no-shadow"
							large
							v-bind="attrs"
							v-on="on"
							@click="handleReject"
						>
							<v-icon
								color="white"
								large
							>
								mdi-phone-hangup
							</v-icon>
						</v-btn>
					</template>

					<span>{{ $t("ignore") }}</span>
				</v-tooltip>
			</v-row>

			<v-row v-if="isOnCall" class="d-flex justify-space-around mb-8">
				<v-tooltip top>
					<template #activator="{ on, attrs }">
						<v-btn
							
							fab
							color="turquoise-dark"
							class="elevation-0 mb-n2"
							v-bind="attrs"
							:disabled="disableForward"
							v-on="on"
							@click="isTransferCallOpen = true"
							style="width: 50px; height: 50px; margin-top: 10px"
						>
							<v-img
								max-width="20px"
								src="@/assets/img/call/arrow-right.png"
							/>
						</v-btn>
					</template>

					<span>{{ $t("transfer") }}</span>
				</v-tooltip>

				<v-tooltip top>
					<template #activator="{ on, attrs }">
						<v-btn
							fab
							large
							color="error"
							class="elevation-0"
							v-bind="attrs"
							:disabled="disableHangUp"
							v-on="on"
							@click="handleHangup"
							style="margin-top:-8px"
						>
							<v-img
								src="@/assets/img/call/phone-call-end.png"
								max-width="34"
							/>
						</v-btn>
					</template>

					<span>{{ $t("hangup-cta") }}</span>
				</v-tooltip>

				<v-tooltip top>
					<template #activator="{ on, attrs }">
						<v-btn
							v-bind="attrs"
							fab
							color="turquoise-dark"
							class="button elevation-0"
							:class="blinkButtonClass"
							:disabled="disableOnHold"
							v-on="on"
							@click="toggleOnHoldCall"
							style="width: 50px; height: 50px; margin-top: 10px"
						>
							<v-progress-circular
								v-if="onHoldLoading"
								:width="4"
								color="primary"
								indeterminate
							/>
							<div v-else>
							<v-img
								v-if="activeCall.onHold"
								max-width="36px"
								class="pl-2"
								src="@/assets/img/call/play.png"
							/>
							<v-img
								v-else
								max-width="20px"
								src="@/assets/img/call/pause.png"
							/>
							</div>
						</v-btn>
					</template>

					<span>{{ $t(activeCall.onHold ? "resume-cta" : "hold-cta") }}</span>
				</v-tooltip>

				<!-- <v-tooltip top>
					<template #activator="{ on }">
						<v-btn
							outlined
							fab
							color="primary"
							:disabled="isTransfering"
							v-on="on"
							@click="$emit('showDialpad')"
						>
							<v-icon size="md">
								mdi-dialpad
							</v-icon>
						</v-btn>
					</template>

					<span>{{ $t("show-dialpad") }}</span>
				</v-tooltip> -->

			</v-row>
		</v-card-actions>

		<transfer-call
			v-if="isTransferCallOpen"
			:open="isTransferCallOpen"
			:groups="groups"
			@cancel="isTransferCallOpen = false"
			@confirm="transferCall($event)"
		/>

		<v-divider style="width: 150px; border-top: 4px solid; margin :auto" class="mb-n6"> </v-divider>
	</v-layout>
</template>

<script>
import {
	mapActions,
	mapGetters,
	mapMutations
} from "vuex";
import { updateConversation } from "@/common/utils";
import { EnqueueCallException } from "../../../composables/voice/exceptions";
import useConversation from "@/composables/conversation";
import TransferCall from "../TransferCall.vue";

const conversationsHook = useConversation();
const DENIED = "denied";

export default {
	name: "CallActions",

	components: {
		TransferCall
	},

	data() {
		return {
			enableOnHoldButton: false,
			isTransferCallOpen: false,
			onHoldLoading: false,
			redirectConversationLoading: undefined,
			showDialpad: false,
			transferStarted: false,
			groups: null
		};
	},

	computed: {
		...mapGetters("voice", [
			"isOnCall",
			"hasIncomingCall",
			"activeCall",
			"isCallAccepted",
			"isMicroOn",
			"activeCallConvoSid",
			"activeCallConvoState"
		]),

		...mapGetters("conversation", ["filteredConversations", "currentParticipant"]),
		...mapGetters("clinic", ["clinicData"]),
		...mapGetters("groups", ["allGroups"]),

		disableConversationRedirect() {
			return !this.activeCallConvoSid;
		},

		blinkButtonClass() {
			return this.activeCall.onHold && !this.onHoldLoading ? "blink" : "";
		},

		disableOnHold() {
			// TODO: find a way to disable while callee has not answered yet
			return !this.enableOnHoldButton || this.onHoldLoading || this.transferStarted;
		},

		isTransfering() {
			return this.transferStarted;
		},

		disableForward() {
			// TODO: find a way to disable while callee has not answered yet
			return !this.isCallAccepted || this.isTransfering;
		},

		disableHangUp() {
			return this.activeCall.onHold;
		}
	},

	watch: {
		isCallAccepted: {
			immediate: true,

			handler(isCallAccepted) {
				this.enableOnHoldButton = isCallAccepted;
			}
		},

		isTransferCallOpen: {
			async handler(val) {
				if (val) {
					await this.getTransferData();
				}
			}
		}
	},

	async created() {
		await this.getTransferData();
	},

	methods: {
		...mapActions("conversation", ["displayConversation"]),

		...mapMutations("voice", [
			"setIsMicroGuideOpen",
			"setIsMicroOn",
			"enableMicrophone",
			"disableMicrophone"
		]),

		async getTransferData() {
			try {
				const { data } = await this.axios.get("voice/v1/calls/status");

				// Data received is an array of array.
				this.groups = data.flat();
			} catch (error) {
				console.error(error);
				this.$store.commit("alerts/add", {
					type: "error",
					message: this.$t("error.get-groups"),
					timeout: true
				});
			}
		},

		async toggleOnHoldCall() {
			this.onHoldLoading = true;
			try {
				if (this.activeCall.onHold) {
					await this.activeCall.resume();
				} else {
					await this.activeCall.hold();
				};
			} catch (err) {
				const message = err instanceof EnqueueCallException
					? this.$t("error.enqueue")
					: this.$t("error.dequeue");

				this.$store.commit("alerts/add", {
					type: "error",
					message,
					timeout: true
				});
			} finally {
				this.onHoldLoading = false;
			}
		},

		findGroupFromPhone(phoneNumber) {
			return this.allGroups.find((group) => group.phoneNumber === phoneNumber);
		},

		async handleAccept() {
			// Clear any digits from the dialpad before answer
			this.$store.commit("voice/clearDigitsPressed");
			try {
				const status = await navigator.permissions.query({
					name: "microphone"
				});

				if (status.state === DENIED) {
					this.setIsMicroGuideOpen(true);
				} else {
					await this.activeCall.accept();

					const callSid = this.activeCall.callInstance.parameters.CallSid;
					const { data: fromGroup } = await this.axios.get(`voice/v1/calls/from/${callSid}`);

					if (fromGroup && fromGroup.id !== this.currentParticipant.identity) {
						this.$store.commit("alerts/add", {
							type: "success",
							message: this.$t("changing-group", { label: fromGroup.label }),
							timeout: true
						});

						await conversationsHook.bootstrap(fromGroup.id);
						this.$store.commit("conversation/setCurrentGroup", fromGroup.id);
					}

					if (this.activeCallConvoSid) {
						this.$store.dispatch("conversation/displayConversation", this.activeCallConvoSid);
					}
				}
			} catch (err) {
				console.error(err);
			}
		},

		handleReject() {
			// Warning: This action reject all incoming user group call too
			this.activeCall?.rejectAll();
		},

		async handleHangup() {
			this.activeCall?.hangUp();
			this.$store.commit("voice/clearDigitsPressed");
			if (window.mediaStream) {
				window.mediaStream.getTracks().forEach((tracks => {
					tracks.stop();
				}));
			}
		},

		async redirectConversation() {
			if (!this.activeCallConvoSid) return;

			try {
				this.redirectConversationLoading = true;
				this.$store.commit("conversation/toggleStatusFilter", "active");

				if (this.activeCallConvoState === "inactive") {
					await this.versationStatus(this.activeCallConvoSid);
				}

				if (this.$route.path == "/conversations") {
					this.displayConversation(this.activeCallConvoSid);
				} else {
					this.$router.push({
						name: "conversations",
						params: {
							sid: this.activeCallConvoSid
						}
					});
				};
			} catch (err) {
				this.$store.commit("alerts/add", {
					type: "error",
					message:this.$t("error.display-contact-info"),
					timeout: true
				});
			} finally {
				this.redirectConversationLoading = false;
			}
		},

		async versationStatus(sid) {
			try {
				await updateConversation(sid, {
					state: "active"
				});

				if (!this.filteredConversations.length) {
					this.$store.commit("conversation/removeActiveConversation");
				}
			} catch (err) {
				this.$store.commit("alerts/add", {
					type: "error",
					message: this.$t("error.update-status"),
					timeout: true
				});
			}
		},

		async transferCall({ group }) {
			let checkCallStatusInterval;

			this.transferStarted = true;
			this.$emit("transfering");
			try {
				await this.activeCall.transferCall(group);
				checkCallStatusInterval = setInterval(() => {
					const status = this.activeCall?.callInstance?.status();

					// Waiting for other user to pick up
					if (status === "ringing" || status === "open") {
						clearInterval(checkCallStatusInterval);
					} else {
						clearInterval(checkCallStatusInterval);
						this.transferStarted = false;
						return;
					}
				}, 1000);
			} catch (err) {
				clearInterval(checkCallStatusInterval);
				this.transferStarted = false;
			} finally {
				this.isTransferCallOpen = false;
				this.$emit("transfering");
			}
		}
	}
};
</script>

<style scoped>
.button:focus::before {
	opacity: 0 !important;
}

.no-shadow {
	box-shadow: none !important;
}
.blink {
	animation: blinking ease-out 1s infinite;
}

@keyframes blinking {
	0% {
		background-color: var(--v-primary-base);
	}

	100% {
		background-color: #fff;
	}
}
</style>
