<template>
<svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M15.6999 19.6211C17.275 20.5305 18.0634 20.9851 18.7097 20.9172C19.2734 20.8579 19.7851 20.5621 20.1182 20.1035C20.5001 19.578 20.5001 18.6691 20.5001 16.8515L20.5001 7.14853C20.5001 5.3309 20.5001 4.42218 20.1182 3.89662C19.7851 3.43806 19.2734 3.14206 18.7097 3.08281C18.0634 3.01489 17.275 3.46934 15.6999 4.37877L7.29986 9.22852C5.72468 10.1379 4.93722 10.5929 4.67291 11.1865C4.44236 11.7043 4.44236 12.296 4.67291 12.8138C4.93711 13.4072 5.72457 13.8619 7.29845 14.7705L15.6999 19.6211Z" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

    
    </template>
    
    <script>
    export default {
        name: "ArrowLeftIcon"
    };
    </script>
    
    <style lang="scss" scoped>
    
    </style>
    