<template>
	<v-layout align-center justify-center column class="mt-8">
		<v-avatar
			size="100"
			style="border-width: 5px; border-color: wheat; border-style: solid;"
		>
			<img
				v-if="hasClient"
				:src="`https://api.dicebear.com/8.x/notionists/svg?seed=${
					client.name.first + client.name.last
				}&backgroundType=gradientLinear&backgroundColor=b6e3f4,c0aede,d1d4f9&size=80`"
				alt="avatar"
			>

			<v-icon
				v-else
				size="100"
			>
				mdi-account-circle-outline
			</v-icon>
		</v-avatar>

		<span class="mt-2 text-h4 font-weight-bold truncate">
			{{ participantLabel }}
		</span>

		<v-img
			src="@/assets/img/call/phone-call.png"
			width="50"
			height="50"
			class="mt-3"
		/>

		<v-btn
			disabled
			outlined
			rounded
			large
			text
			class="mt-5"
		>
		{{ callDuration }}
		</v-btn>
</v-layout>
</template>

<script>
import { phoneNumberFormater, initialsGenerator } from "@/common/utils";
import { mapGetters } from "vuex";
import { isEmpty } from "lodash";

export default {
	name: "CallInfo",

	computed: {
		...mapGetters("conversation", ["hasClient", "client"]),
		...mapGetters("voice", ["isOnCall", "activeCall", "callDetails"]),

		callDuration() {
			return this.activeCall?.duration;
		},

		initials() {
			return initialsGenerator(
				this.participantName?.length ? `${this.participantName?.first} ${this.participantName?.last}` : ""
			);
		},

		participantLabel() {
			return isEmpty(this.participantName)
				? this.participantPhoneNumber
				: `${this.participantName.first} ${this.participantName.last}`;
		},

		participantName() {
			return this.callDetails?.name;
		},

		participantPhoneNumber() {
			return phoneNumberFormater(this.callDetails?.phoneNumber);
		}
	}
};
</script>

<style scoped>
.truncate {
	word-wrap: break-word;
	overflow: hidden;
	display: -webkit-box;
	-webkit-line-clamp: 1;
	-webkit-box-orient: vertical;
	word-break: break-all;
}
</style>
