<template>
<svg
width="38"
height="38"
viewBox="0 0 38 38"
fill="none"
xmlns="http://www.w3.org/2000/svg"
>
<path fill-rule="evenodd" clip-rule="evenodd" d="M19.304 0.666504C9.17881 0.666504 0.970703 8.87462 0.970703 18.9998C0.970703 29.1251 9.17881 37.3332 19.304 37.3332C29.4293 37.3332 37.6374 29.1251 37.6374 18.9998C37.6374 8.87462 29.4293 0.666504 19.304 0.666504ZM18.0077 10.4813C17.0873 10.4813 16.3411 11.2275 16.3411 12.148V14.3702H13.0078V12.148C13.0078 9.38658 15.2463 7.14801 18.0077 7.14801H20.6003C23.3618 7.14801 25.6003 9.38659 25.6003 12.148V14.8414C25.6003 16.1675 25.0736 17.4392 24.1359 18.3769L21.4589 21.0539C21.1463 21.3665 20.9707 21.7904 20.9707 22.2324V25.4813H17.6374V22.2324C17.6374 20.9063 18.1642 19.6346 19.1018 18.6969L21.7789 16.0199C22.0914 15.7073 22.267 15.2834 22.267 14.8414V12.148C22.267 11.2275 21.5208 10.4813 20.6003 10.4813H18.0077ZM17.6374 27.3332V31.0369H20.9707V27.3332H17.6374Z" fill="currentcolor" />
</svg>
</template>
<script>
export default {
	name:"QuestionsIcon",
                    
	props:{
		color:{
			type:String,
			default:"#fff"
		}
	}
};
</script>