<template>
	<div v-if="isSubmitting" :class="['import-contacts-progress', devMode && 'dev-mode-attributes']">
		<v-card class="px-3 py-1">
			<v-icon
				class="mr-1"
				color="primary"
				dark
				large
			>
				mdi-information
			</v-icon>

			<span>{{ $t("import-in-progress") }}</span>

			<v-progress-circular
				size="30"
				:width="5"
				color="primary"
				class="ml-10"
				indeterminate
			/>
		</v-card>
	</div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
	name: "ImportContactsProgress",

	computed: {
		...mapGetters("contact", ["isSubmitting"]),

		devMode() {
			return ["development", "staging"].includes(process.env.NODE_ENV);
		},

		env() {
			return process.env.NODE_ENV;
		}
	}
};
</script>

<style scoped>
.import-contacts-progress {
	position: absolute;
	right: 12px;
	bottom: 12px;
	z-index: 2;
}

.dev-mode-attributes {
	right: 55px;
}
</style>