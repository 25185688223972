<template>
<svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M17.0007 0.398438C7.83398 0.398438 0.333984 7.88177 0.333984 17.0984C0.333984 25.4318 6.43398 32.3484 14.4007 33.5984V21.9318H10.1673V17.0984H14.4007V13.4151C14.4007 9.23177 16.884 6.93177 20.7007 6.93177C22.5173 6.93177 24.4173 7.24844 24.4173 7.24844V11.3651H22.3173C20.2507 11.3651 19.6007 12.6484 19.6007 13.9651V17.0984H24.234L23.484 21.9318H19.6007V33.5984C23.5281 32.9782 27.1043 30.9743 29.6839 27.9485C32.2634 24.9228 33.6762 21.0745 33.6673 17.0984C33.6673 7.88177 26.1673 0.398438 17.0007 0.398438Z" fill="currentColor"/>
</svg>

</template>
<script>
export default {
	name:"FacebookIcon"
};
</script>