import EMOJIS from "./emojis";
import { MessagingEvent } from "../composables/messaging/messaging-event";
import axios from "axios";
import messaging from "../composables/messaging";
import useConversation from "../composables/conversation";
import store from "../store";
import DOMPurify from "dompurify";

const conversationsHook = useConversation();

/**
 * Checks if the phone number is either an open phone or a twilio number in the database.
 * @param {*} phoneNumber - The phone number to verify.
 * @returns {boolean} - True if the phone number is an open phone or twilio, false otherwise.
 */
export async function isOpenPhoneOrTwilio(phoneNumber) {
	const { data: allOrganizations } = await axios.get("organizations");

	// Might be a little hard on the BE. Ideally have an endpoint with all twilio numbers.
	const phonesObj = await Promise.all(
		allOrganizations.map(async (org) => {
			const { data: data } = await axios.get(`organizations/${org.id}/phone-numbers`);

			return data;
		})
	);

	const phones = phonesObj.flat(Infinity).map((phone) => {
		return phone.phoneNumber.replace(/\D/g, "");
	});

	phones.push(...[process.env.VUE_APP_OPEN_PHONE_1, process.env.VUE_APP_OPEN_PHONE_2]);
	phoneNumber = phoneNumber.replace(/\D/g, "");
	return phones.includes(phoneNumber);
};

// Formats phone number to (XXX) XXX-XXXX
export function phoneNumberFormater(phoneNumber) {
	if (!phoneNumber) return;
	const cleaned = `${phoneNumber.replace(/\D/g, "")}`;
	const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);

	return match ? `(${match[2]}) ${match[3]}-${match[4]}` : phoneNumber;
};

// Get line type from a client record
// export function getLineType(client) {
// 	let result = null;

// 	try {
// 		const lookup = JSON.parse(client.lookup);

// 		result = lookup?.lineTypeIntelligence?.type;
// 	} catch (err) {}

// 	try {
// 		const insight = JSON.parse(client.insight);

// 		result = insight?.attributes?.connection || result;
// 	} catch (err) {}

// 	return result;
// }

export function formatToE164(phoneNumber) {
	const numericOnly = phoneNumber.replace(/\D/g, "");
	const defaultCountryCode = "+1";
	const cleanNumber = numericOnly.startsWith("0") ? numericOnly.slice(1) : numericOnly;
	const formattedNumber = cleanNumber.startsWith("+") ? cleanNumber : defaultCountryCode + cleanNumber;

	return formattedNumber;
};

export function initialsGenerator(fullName) {
	if (!fullName) return;

	const rgx = new RegExp(/(\p{L}{1})\p{L}+/, "gu");

	let initials = [...String(fullName).matchAll(rgx)] || [];

	initials = `${initials.shift()?.[1] || ""}${initials.pop()?.[1] || ""}`.toUpperCase();

	return initials;
};

export function initialLastNameGenerator(fullName) {
	if (!fullName) return "";

	fullName = fullName.replace(/\s+/g, " ").trim();

	const names = fullName.split(" ");

	const firstName = names[0];

	const lastNameInitial = names.length > 1 ? names[1][0] : "";

	return firstName + " " + lastNameInitial.toUpperCase() + ".";
}

export async function fetchUsers() {
	const { data: users } = await axios.get("users");

	return users
		.map((user) => {
			const fullName = `${user.name.first} ${user.name.last}`;

			return {
				identity: user.id,
				fullName,
				phoneNumber: user.settings ? user.settings.phoneNumber : "1111111111"
			};
		})
		.sort((userA, userB) => userA.fullName - userB.fullName);
};

export function convertTextToEmoji(text) {
	let textArr = text.split(" ");

	Object.entries(EMOJIS).map(([text, emoji]) => {
		textArr.map((newText, index) => {
			if (newText === text) {
				textArr[index] = emoji;
			}
		});
	});

	return textArr.join(" ");
};

export async function createConversation(url, options) {
	if (store.getters["clinic/isFrance"]) {
		const thread = await messaging.threadList.create(options.to, options.body, options.attributes);

		new MessagingEvent().onThreadCreated(thread);

		return {
			data: thread
		};
	}

	return await axios.post(url, options);
};

export async function updateConversation(id, options) {
	if (store.getters["clinic/isFrance"]) {
		const { state, ...rest } = options; // FIXME: use a better impl to update thread status

		const { data: updatedThread } = await messaging.threadList.update(id, {
			...rest,
			status: state
		});

		new MessagingEvent().onThreadUpdated(updatedThread);

		return {
			data: updatedThread
		};
	}

	return await axios.put(`conversations/${id}`, options);
};

export async function deleteConversation(id) {
	if (store.getters["clinic/isFrance"]) {
		return await messaging.threadList.remove(id);
	}

	return await axios.delete(`conversations/${id}`);
};

export async function shortenURL(url) {
	const { data: tinyUrl } = await axios.post("link/create", { url });

	return tinyUrl;
};

export function validatePhoneForE164(phone) {
	const regEx = /^\+[1-9]\d{10,14}$/;

	return regEx.test(phone);
};

// Force download files
// downloadCallback() will receive both the href and the extension of the file to be downloaded
export async function download(url, downloadCallback) {
	const { data: fileBlob } = await axios.create().get(url, {
		responseType: "blob",
		withCredentials: false
	});

	const href = window.URL.createObjectURL(fileBlob);
	const extension = fileBlob.type.split("/")[1];

	try {
		await downloadCallback(href, extension);
	} catch (err) {
		throw err;
	} finally {
		window.URL.revokeObjectURL(url);
	}
};

export async function reloadConversation(currentGroup) {
	try {
		if (store.getters["clinic/isFrance"]) {
			await messaging.bootstrap();
		} else {
			await conversationsHook.bootstrap(currentGroup);
		}
	} catch (error) {
		console.error(error);
	}
};

export function currentLocale(locale) {
	const convertLocale = {
		"en-CA": "en-US",
		"fr-FR": "fr-FR",
		"fr-CA": "fr-CA"
	};

	return convertLocale[locale];
};

export const toBase64 = file => new Promise((resolve, reject) => {
	const reader = new FileReader();

	reader.readAsDataURL(file);
	reader.onload = () => resolve(reader.result);
	reader.onerror = reject;
});

export function formatContent(content) {
	const urlRegex = /(https?:\/\/[^\s]+)|(www\.[^\s]+)/g;

	const sanitizedMessage = DOMPurify.sanitize(content);
	const formattedMessage = sanitizedMessage.replace(urlRegex, (link) => {
		const url = link.startsWith("http")? link : `https://${link}`;

		return `<a href='${url}' target='_blank' rel='noopener noreferrer'>${link}</a>`;
	});

	return formattedMessage;
}