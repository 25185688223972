<template>
	<div class="alerts">
		<v-alert
			v-for="(alert, index) in list"
			:key="index"
			rounded="lg"
			dark
			dismissible
			:icon="getIcon(alert.type)"
			:color="alert.type"
			elevation="0"
			class="my-4"
			:value="alert.show"
			transition="slide-y-transition"
		>
			{{ alert.message }}
			<span v-if="responseMessage(alert.response)">
				- {{ alert.response.data.message }}
			</span>
		</v-alert>
	</div>
</template>

<script>
import { mapFields } from "vuex-map-fields";

export default {
	name: "Alerts",

	computed: {
		...mapFields("alerts", ["list"])
	},

	methods: {
		getIcon(type) {
			switch (type) {
				case "error":
					return "mdi-alert";

				case "info":
					return "mdi-information";

				case "warning":
					return "mdi-alert-circle";

				default:
					return "mdi-check-circle";
			}
		},

		responseMessage(response) {
			return response?.data?.message;
		}
	}
};
</script>

<style scoped lang="scss">
	.alerts {
		position: fixed;
		width: 50%;
		left: 50%;
		margin-left: -25%;
		z-index: 1000;
	}
</style>
