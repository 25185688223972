import moment from "moment";
import axios from "axios";
import { DequeueCallException, EnqueueCallException } from "./exceptions";

export const QUEUE_ACTION_ENQUEUE = "enqueue";
export const QUEUE_ACTION_DEQUEUE = "dequeue";
export const QUEUE_ACTION_CLEAR = "clear";

class PhoneCall {
	callDetails = null;
	recordingSid = null;
	_duration = 0;
	_onHold = false;
	direction = null;

	constructor(callInstance) {
		this.callInstance = callInstance;
	}

	mute = (muteToggle) => {
		this.callInstance?.mute(muteToggle);
	};

	countDuration = () => {
		if (durationTimer) clearInterval(durationTimer);

		var durationTimer = setInterval(() => {
			this._duration++;
		}, 1000);
	};

	accept = () => {
		this.callInstance?.accept();
	};

	hangUp = () => {
		this.callInstance?.disconnect();
	};

	reject = () => {
		this.callInstance?.reject();
	};

	rejectAll = () => {
		this.callInstance?.reject();

		if (this.direction === "inbound"){
			// Reject all other calls
			axios.post("voice/v1/calls/rejectAll", {
				callSid: this.callSid
			});
		}
	};

	resume = async () => {
		try {
			this._onHold = false;
			this.mute(false);

			await axios.post("voice/v1/calls/resume", {
				callSid: this.callSid
			});
		} catch (err) {
			throw new DequeueCallException(`Failed to resume call: ${this.callSid}`);
		}
	};

	hold = async () => {
		try {
			this._onHold = true;
			this.mute(true);

			await axios.post("voice/v1/calls/hold", {
				callSid: this.callSid
			});
		} catch (err) {
			this._onHold = false;
			this.mute(false);
			throw new EnqueueCallException(`Failed to enqueue call ${this.callSid}`);
		}
	};

	async transferCall(group) {
		try {
			await axios.post("voice/v1/calls/transfer", {
				callSid: this.callSid,
				to: group.phone,
				toUserGroup: group.id
			});
		} catch (error) {
			console.error(error);
			this.$store.commit("alerts/add", {
				type: "error",
				message: this.$t("error.transfer-call"),
				timeout: true
			});
		}
	}

	async getData() {
		const { data } = await axios.get(`voice/v1/calls/from/${this.callSid}`);

		return data;
	}

	get duration() {
		return moment.utc(this._duration * 1000).format("mm:ss");
	}

	get status() {
		return this.callInstance?.status();
	}

	get onHold() {
		return this._onHold;
	}

	get callSid() {
		return this.callInstance?.parameters?.CallSid;
	}
}

export default PhoneCall;