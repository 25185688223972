<template>
	<svg
		width="40"
		height="40"
		viewBox="0 0 40 40"
		fill="currentcolor"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M12.5163 33.0029C14.7188 34.2732 17.2742 34.9999 19.9994 34.9999C28.2837 34.9999 35 28.2843 35 20C35 11.7157 28.2843 5 20 5C11.7157 5 5 11.7157 5 20C5 22.7252 5.72674 25.2806 6.99699 27.4831L7.00191 27.4917C7.12413 27.7036 7.18577 27.8104 7.21368 27.9114C7.24001 28.0067 7.24737 28.0923 7.24062 28.1909C7.23338 28.2968 7.19767 28.4067 7.12447 28.6263L5.8431 32.4704L5.84149 32.4755C5.57113 33.2865 5.43595 33.6921 5.53231 33.9623C5.61632 34.1979 5.80281 34.3838 6.03841 34.4678C6.30803 34.564 6.71174 34.4294 7.51924 34.1603L7.5293 34.1565L11.3734 32.8752C11.5923 32.8022 11.7036 32.7652 11.8093 32.758C11.9079 32.7512 11.993 32.7601 12.0882 32.7865C12.1895 32.8144 12.2964 32.8761 12.5094 32.999L12.5163 33.0029Z"
			stroke="currentcolor"
			stroke-width="2"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
	</svg>
</template>
<script>
export default {
	name:"TextIcon",

	props: {
		color:{
			type:String,
			default:"#000"
		}
	}
};
</script>