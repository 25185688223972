<template>
	<svg
		width="30"
		height="30"
		viewBox="-3 -3 30 30"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		>
		<path
			d="M7.01302 23.4023C8.77501 24.4185 10.8194 24.9999 12.9995 24.9999C19.6269 24.9999 25 19.6274 25 13C25 6.37258 19.6274 1 13 1C6.37258 1 1 6.37258 1 13C1 15.1802 1.58139 17.2245 2.59759 18.9865L2.60153 18.9933C2.6993 19.1629 2.74861 19.2483 2.77094 19.3291C2.79201 19.4053 2.79789 19.4739 2.7925 19.5527C2.7867 19.6375 2.75814 19.7254 2.69958 19.901L1.67448 22.9763L1.67319 22.9804C1.4569 23.6292 1.34876 23.9537 1.42585 24.1698C1.49306 24.3583 1.64225 24.5071 1.83073 24.5743C2.04642 24.6512 2.36939 24.5435 3.0154 24.3282L3.02344 24.3252L6.09873 23.3001C6.27383 23.2418 6.36285 23.2122 6.44746 23.2064C6.52633 23.201 6.59437 23.2081 6.67057 23.2292C6.75158 23.2516 6.83711 23.3009 7.00751 23.3992L7.01302 23.4023Z"
			stroke="currentcolor"
			stroke-opacity="0.8"
			stroke-width="2"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
	</svg>
</template>

<script>
export default {
	name: "ChatIcon",

	props: {
		color:{
			type:String,
			default:"#000"
		}
	}
};
</script>

<style lang="scss" scoped>

</style>