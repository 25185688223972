<template>
	<v-dialog
		v-model="show"
		:min-width="modalMaxWidth"
		:persistent="isPersistent"
		:width="modalWidth"
		color="transparent"
		@click:outside="isPersistent ? () => {} : handleClose()"
	>
	<v-card>
		
		<v-row no-gutters class="pa-5 rounded-xxl-s ">
			<v-col cols="5">
				<v-card color="grey lighten-3 rounded-xl pa-5" class="d-flex flex-column  gap-5" flat height="100%">
					<v-card-subtitle v-if="modalTitle" class="text-h5 font-weight-bold primary--text pa-0  ">
						{{ modalTitle }}
					</v-card-subtitle>
					<v-card-subtitle v-if="modalSubTitle" class="text-h5 lh-20 primary--text pa-0  ">
						{{ modalSubTitle }}
					</v-card-subtitle>
					<v-card-text class="pa-0">
						<v-card-subtitle class="text-h6  primary--text pa-0 ">
							<v-row no-gutters align="center">
								<v-icon size="24" color="primary" class="mr-6">$CallIcon</v-icon>
								<span>{{ phoneInformation }}</span>
							</v-row>
					</v-card-subtitle>
						<v-card-subtitle class="text-h6  primary--text pa-0 ">
							<v-row no-gutters align="center">
								<v-icon size="24" color="primary" class="mr-6">$EmailIcon</v-icon>
								<span>{{ emailInformation }}</span>
							</v-row>
					</v-card-subtitle>
						<v-card-subtitle class="text-h6  primary--text pa-0 ">
							<v-row no-gutters align="center">
								<v-icon size="24" color="primary" class="mr-6">$LocaleIcon</v-icon>
								<span>{{ localeInformation }}</span>
							</v-row>
							
					</v-card-subtitle>
					</v-card-text>
					<v-row no-gutters align="center" class="flex-1">
						<v-img class="mx-auto" max-width="78%" :src="logo" alt="logo" />
					</v-row>
					

				</v-card>
			</v-col>
				<v-col  cols="7">
					<v-card flat class="h-full d-flex flex-column justify-space-between">
				<slot name="details" />
				<v-sheet v-if="modalLoading" class="d-flex flex-column justify-center align-center py-12">
					<v-progress-circular :size="50" :width="7" color="primary" indeterminate />
				</v-sheet>

				<template v-else>
					
						<slot />

<v-card-actions v-if="!isHideCta" class="pl-5 pr-0 ">
	<v-btn
		v-if="!!modalCancelText"
		color="turquoise-light"
		outlined
		:disabled="isCancelDisable"
		:loading="isCancelLoading"
		class="ml-auto px-5 px-8 text-none font-weight-bold rounded-3 ml-auto"
		@click="$emit('cancel')"
	>
		{{ modalCancelText }}
	</v-btn>

	<v-spacer v-if="showSpacer" />

	<v-btn
		v-if="!!modalExtraText"
		color="turquoise-light"
		:disabled="isExtraDisable"
		:loading="isExtraLoading"
		class="py-5 px-8"
		@click="$emit('extra')"
	>
		{{ modalExtraText }}
	</v-btn>

	<v-btn
		v-if="!!modalConfirmText"
		color="turquoise-light"
		:disabled="isConfirmDisable"
		:loading="isConfirmLoading"
		class="py-5 px-10 rounded-3 text-none white--text font-weight-bold"
		@click="$emit('confirm')"
	>
{{ modalConfirmText }}
	</v-btn>
	<slot name="close" />
</v-card-actions>
				
				
				</template>
			</v-card>
				</v-col>
				
</v-row>

	
	</v-card>
	</v-dialog>
</template>

<script>
export default {
	name: "ModalSupport",

	props: {
		persistent: {
			type: Boolean,
			default: false
		},

		showCloseButton: {
			type: Boolean,
			default: false
		},

		hideHeader: {
			type: Boolean,
			default: false
		},

		type: {
			type: String,
			default: ""
		},

		value: {
			type: Boolean,
			default: false
		},

		title: {
			type: String,
			default: ""
		},

		subTitle: {
			type: String,
			default: ""
		},
		
		phoneInformation:{
			type: String,
			default: ""
		},

		emailInformation:{
			type: String,
			default: ""
		},

		localeInformation:{
			type: String,
			default: ""
		},

		width: {
			type: String,
			default: ""
		},

		height:{
			type: String,
			default: ""
		},

		maxWidth: {
			type: String,
			default: ""
		},


		loading: {
			type: Boolean,
			default: false
		},

		cancelText: {
			type: String,
			default: ""
		},

		confirmText: {
			type: String,
			default: ""
		},

		extraText: {
			type: String,
			default: ""
		},

		confirmLoading: {
			type: Boolean,
			default: false
		},

		cancelLoading: {
			type: Boolean,
			default: false
		},

		extraLoading: {
			type: Boolean,
			default: false
		},

		confirmDisabled: {
			type: Boolean,
			default: false
		},

		extraDisabled: {
			type: Boolean,
			default: false
		},

		isCtaDisable: {
			type: Object,
			default: () => {}
		},

		hideCta: {
			type: Boolean,
			default: false
		},

		showSaveIcon: {
			type: Boolean,
			default: false
		},

		message: {
			default: undefined,
			type: Object
		},

		showSpacer: {
      		type: Boolean,
      		default: true
    	},

		titleClass: {
			type: String,
			default: ""
		}
	},

	data() {
		return {
			logo: require("@/assets/img/inservio-logo.svg")
		};
	},

	computed: {
		show: {
			get() {
				return this.value;
			},

			set(value) {
				this.$emit("input", value);
			}
		},

		modalType() {
			return this.type;
		},

		modalTitle() {
			if (typeof this.title === "object") {
				return `<span style="${this.title.style}">${this.title.text}</span>`;
			} else {
				return this.title;
			}
		},

		modalSubTitle() {
			return this.subTitle;
		},

		phoneInformationTitle(){
			return this.phoneInformation;

		},

		emailInformationTitle(){
			return this.emailInformation;
		},

		localeInformationTitle(){
			return this.localeInformation;

		},

		modalWidth() {
			return this.width;
		},

		modalMaxWidth() {
			return this.maxWidth;
		},


		modalCancelText() {
			return this.cancelText;
		},

		modalConfirmText() {
			return this.confirmText;
		},

		modalExtraText() {
			return this.extraText;
		},

		isConfirmDisable() {
			return this.isCtaDisable?.confirm || this.confirmDisabled;
		},

		isCancelDisable() {
			return this.isCtaDisable?.cancel;
		},

		isExtraDisable() {
			return this.isCtaDisable?.cancel;
		},

		modalLoading() {
			return this.loading;
		},

		isHideCta() {
			return this.hideCta;
		},

		isConfirmLoading() {
			return this.confirmLoading;
		},

		isCancelLoading() {
			return this.cancelLoading;
		},

		isExtraLoading() {
			return this.extraLoading;
		},

		saveIcon() {
			return this.showSaveIcon;
		},

		isPersistent() {
			return this.persistent;
		}
	},

	methods: {
		handleClose() {
			this.show = false;
			this.$emit("close");
			this.$emit("cancel", { isClosing: true });
		}
	}
};
</script>

<style scoped lang="scss">
.h-full{
	height: 100% !important;
}
.title {
	line-height: 36px !important;
}
.lh-20{
	line-height: 1.5rem !important;
}
::v-deep .v-dialog{
	box-shadow: none !important;
}
</style>
