<template>
	<v-card v-if="connected && profile.clinic && notShowLog($route.fullPath)" flat>
		<v-navigation-drawer
			v-model="drawer"
			width="84"
			permanent
			app
			class="navigation-on-top py-3 pl-3"
		>
			<div
				class="nav-menu  primary rounded-xl"
			>
				<div>
					<div class="d-flex align-center my-10">
						<v-img src="@/assets/img/logo-sm.svg" width="80" height="80" />
					</div>

					<v-list nav>
						<template v-for="item in items.top">
							<v-list-item
								v-if="item.single"
								v-slot="listItem"
								:key="item.id"
								class="px-0"
								active-class="white"
								link
								:to="item.route"
								@click="item.action"
							>
								<v-list-item-icon class="mx-auto">
									<v-badge :value="hasNotifications(item)" overlap dot>
										<v-icon :color="listItem.active ? 'primary' : 'white'" size="32">
											${{ item.icon }}
										</v-icon>
									</v-badge>
								</v-list-item-icon>
							</v-list-item>

							<v-list-item v-else :key="item.id">
								<v-list-item-icon :class="{ 'mt-3': item.nested }">
									<v-icon color="white">
										${{ item.icon }}
									</v-icon>
								</v-list-item-icon>

								<v-list-item-content class="py-0">
									<v-list-group
										v-model="item.active"
										class="px-0"
										color="secondary--lighten2"
									>
										<v-list-item
											v-for="sublink in item.nested"
											:key="sublink.id"
											:to="sublink.route"
											:disabled="sublink.disabled"
										>
											<v-list-item-title class="font-weight-bold">
												<span
													class="font-weight-bold ml-3"
													:class="sublink.disabled ? 'grey--text' : 'white--text'"
												>
													{{ sublink.title }}
												</span>
											</v-list-item-title>
										</v-list-item>
									</v-list-group>
								</v-list-item-content>
							</v-list-item>
						</template>
					</v-list>
				</div>

				<div>
					<v-list nav>
						<template v-for="item in items.bottom">
							<v-list-item
								v-if="item.single"
								v-slot="listItem"
								:key="item.id"
								class="pa-0"
								active-class="white"
								link
								:to="item.route"
								@click="item.action"
							>
								<v-list-item-icon class="my-4" style="margin: 13px">
									<v-badge :value="hasNotifications(item)" overlap dot>
										<v-icon :color="listItem.active ? 'primary' : 'white'" size="32">
											${{ item.icon }}
										</v-icon>
									</v-badge>
								</v-list-item-icon>
							</v-list-item>

							<v-list-item v-else :key="item.id">
								<v-list-item-icon :class="{ 'mt-3': item.nested }">
									<v-icon color="white">
										{{ item.icon }}
									</v-icon>
								</v-list-item-icon>

								<v-list-item-content class="py-0">
									<v-list-group
										v-model="item.active"
										class="px-0"
										color="secondary--lighten2"
									>
										<v-list-item
											v-for="sublink in item.nested"
											:key="sublink.id"
											:to="sublink.route"
											:disabled="sublink.disabled"
										>
											<v-list-item-title class="font-weight-bold">
												<span
													class="font-weight-bold ml-3"
													:class="sublink.disabled ? 'grey--text' : 'white--text'"
												>
													{{ sublink.title }}
												</span>
											</v-list-item-title>
										</v-list-item>
									</v-list-group>
								</v-list-item-content>
							</v-list-item>
						</template>
					</v-list>
				</div>
			</div>
		</v-navigation-drawer>

		<support-dialog
			v-model="supportDialog"
			:full-path="`${apiUrl}${$route.fullPath}`"
			@closeDialog="supportDialog = false"
		/>

		<admin-dialog v-model="adminDialog" @closeDialog="adminDialog = false" />

		<logout-dialog v-model="logoutDialog" />
	</v-card>
</template>

<script>
import SupportDialog from "../support/SupportDialog";
import LogoutDialog from "../login/LogoutDialog";
import AdminDialog from "../support/AdminDialog";
import { mapGetters } from "vuex";
import { mapFields } from "vuex-map-fields";
import { semVersion } from "../../main";
import { API_URL } from "../../App.vue";
import { bus } from "@/main.js";
import {
	NEW_NAVIGATION_WHITELIST,
	DISABLED_FOR_NEW_NAVIGATION,
	DISABLED_FOR_OLD_NAVIGATION
} from "../../common/const";
export default {
	name: "NavigationMenu",
	components: { SupportDialog, LogoutDialog, AdminDialog },

	data() {
		return {
			drawer: true,
			mini: true,
			lastActive: undefined,
			supportDialog: false,
			logoutDialog: false,
			adminDialog: false,
			oldActiveType: undefined,
			version: semVersion,
			apiUrl: API_URL
		};
	},

	computed: {
		...mapGetters("login", ["connected", "profile", "isDevops"]),
		...mapGetters("ui", ["nav"]),
		
		...mapGetters("conversation", [
			"singleConversation",
			"isInitializing",
			"hasPendingNotifications"
		]),

		...mapFields("blocGrid", ["activePostType", "loadingPosts"]),
		...mapGetters("clinic", ["isSector", "clinicData", "hasWebsite", "isFrance"]),

		hasNotifications() {
			return (item) => {
				return this.hasPendingNotifications && item.title == "Messagerie";
			};
		},

		items() {
			return {
				top: [
					{
						id: "conversations",
						icon: "TextIcon",
						route: "/conversations",
						single: true,
						action: () => {},
						active: false
					},
					{
						id: "contacts",
						icon: "ContactsIcon",
						route: "/contacts",
						single: true,
						action: () => {},
						active: false
					},

					{
						id: "configuration",
						icon: "SettingsIcon",
						route: "/configuration",
						single: true,
						action: () => {},
						active: false
					},
					{
						id: "review",
						icon: "ReviewsIcon",
						route: "/review",
						single: true,
						action: () => {},
						active:false
					},
					// {
					// 	id: "calendarmanagement",
					// 	icon: "CalendarIcon",
					// 	route: "/calendar",
					// 	single: true,
					// 	action: () => {},
					// 	active:false
					// },
					{
						id: "questions",
						icon: "QuestionsIcon",
						single: true,
						action: () => {
							this.supportDialog = true;
						},
						visibilityGuard: () => !this.isSector("notary")
					}
				],
				bottom: [
					{
						id: "logout",
						icon: "LogoutIcon",
						single: true,
						action: this.logoutUser
					}
				]
			};
		}
	},

	watch: {
		mini(val) {
			// Remember last opened nav link when the menu closes
			if (val == true) {
				this.lastActive = this.items.top.find((item) => item.active);
				this.items.top.map((item) => (item.active = false));
			} else {
				if (this.lastActive) {
					this.lastActive.active = true;
				}
			}
		}
	},

	created() {
		bus.$on("resetDisabledNavigation", () => this.resetDisabledNavigation());
	},

	mounted() {
		this.mini = true;

		const isWhitelistClinic = NEW_NAVIGATION_WHITELIST.includes(
			this.profile.clinic
		);
		const disabledItems = isWhitelistClinic
			? DISABLED_FOR_NEW_NAVIGATION
			: DISABLED_FOR_OLD_NAVIGATION;

		this.items.top = this.items?.top?.filter(
			(item) => !disabledItems.includes(item.id) && item
		);
	},

	methods: {
		notShowLog(route) {
			route = "/"+ route ;
			if (route.includes("/login") || route.includes("/logout"))  return false ;
			return true ;
		},

		async logoutUser() {
			this.logoutDialog = true;
		},

		openMicrosite() {
			window.open(`https://${this.clinicData.url}`);
		},

		disableNavigation(currentItem) {
			this.resetDisabledNavigation();

			for (const sublink of this.items.top[2].nested) {
				if (sublink.id !== currentItem) {
					sublink.disabled = true;
				}
			}
		},

		filterBySector(menuItems) {
			if (!Array.isArray(menuItems)) return menuItems;

			return menuItems.filter((menuItem) => {
				return (
					!menuItem.visibilityGuard ||
					(typeof menuItem.visibilityGuard == "function" &&
						menuItem.visibilityGuard())
				);
			});
		},

		resetDisabledNavigation() {
			this.items.top[2]?.nested?.map((sublink) => (sublink.disabled = false));
		},

		changeNavigation(newLink) {
			if (this.oldActiveType !== undefined && this.oldActiveType != newLink) {
				this.loadingPosts = true;
				this.disableNavigation(newLink);
			}

			this.oldActiveType = newLink;
		}
	}
};
</script>

<style scoped lang="scss">
.navigation-on-top {
	z-index: 99 !important;
	height: 100% !important;
}
.isActive {
	background-color: #fff;
}
.v-list--nav .v-list-item,
.v-list--nav .v-list-item:before {
	border-radius: 16px;
}
::v-deep .v-image__image--cover {
	background-size: contain;
}
.nav-menu {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	height: 100% !important;
}
::v-deep .v-navigation-drawer__border {
	display: none !important;
}
</style>
