import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import CallIcon from "@/components/icons/CallIcon.vue";
import TextIcon from "@/components/icons/TextIcon.vue";
import ContactsIcon from "@/components/icons/ContactsIcon.vue";
import CalendarIcon from "@/components/icons/CalendarIcon.vue";
import EmailIcon from "@/components/icons/EmailIcon.vue";
import EmoticonIcon from "@/components/icons/EmoticonIcon.vue";
import FileDocumentIcon from "@/components/icons/FileDocumentIcon.vue";
import ImageIcon from "@/components/icons/ImageIcon.vue";
import LinkIcon from "@/components/icons/LinkIcon.vue";
import LogoutIcon from "@/components/icons/LogoutIcon.vue";
import MoreIcon from "@/components/icons/MoreIcon.vue";
import NotesIcon from "@/components/icons/NotesIcon.vue";
import PaperClipIcon from "@/components/icons/PaperClipIcon.vue";
import QuestionsIcon from "@/components/icons/QuestionsIcon.vue";
import ReviewIcon from "@/components/icons/ReviewIcon.vue";
import ReviewsIcon from "@/components/icons/ReviewsIcon.vue";
import SignatureIcon from "@/components/icons/SignatureIcon.vue";
import SettingsIcon from "@/components/icons/SettingsIcon.vue";
import ChatIcon from "@/components/icons/ChatIcon.vue";
import CallSuccessIcon from "@/components/icons/CallSuccessIcon.vue";
import CallFailedIcon from "@/components/icons/CallFailedIcon.vue";
import InfoIcon from "@/components/icons/InfoIcon.vue";
import FacebookIcon from "@/components/icons/FacebookIcon.vue";
import GmailIcon from "@/components/icons/GmailIcon.vue";
import EyeIcon from "@/components/icons/EyeIcon.vue";
import EyeOffIcon from "@/components/icons/EyeOffIcon.vue";
import LocaleIcon from "@/components/icons/LocaleIcon.vue";
import ArrowLeftIcon from "@/components/icons/ArrowLeftIcon.vue";
import ArrowRightIcon from "@/components/icons/ArrowRightIcon.vue";
const vuetify = new Vuetify({
	icons: {
		iconfont: "mdi",
		values: {
			CallIcon: { component: CallIcon },
			TextIcon: { component: TextIcon },
			ContactsIcon: { component: ContactsIcon },
			CalendarIcon: { component: CalendarIcon },
			EmailIcon: { component: EmailIcon },
			EmoticonIcon: { component: EmoticonIcon },
			FileDocumentIcon: { component: FileDocumentIcon },
			ImageIcon: { component: ImageIcon },
			LinkIcon: { component: LinkIcon },
			LogoutIcon: { component: LogoutIcon },
			MoreIcon: { component: MoreIcon },
			NotesIcon: { component: NotesIcon },
			PaperClipIcon: { component: PaperClipIcon },
			QuestionsIcon: { component: QuestionsIcon },
			ReviewIcon: { component: ReviewIcon },
			ReviewsIcon: { component: ReviewsIcon },
			SignatureIcon: { component: SignatureIcon },
			SettingsIcon: { component: SettingsIcon },
			ChatIcon: { component: ChatIcon },
			CallSuccessIcon: { component: CallSuccessIcon },
			CallFailedIcon: { component: CallFailedIcon },
			InfoIcon: { component: InfoIcon },
			FacebookIcon: { component: FacebookIcon },
			GmailIcon: { component: GmailIcon },
			EyeIcon: { component: EyeIcon },
			EyeOffIcon: { component: EyeOffIcon },
			LocaleIcon: { component: LocaleIcon },
			ArrowLeftIcon: { component: ArrowLeftIcon },
			ArrowRightIcon: { component: ArrowRightIcon }
		}
	  },
	theme: {
		options: { customProperties: true },

		themes: {
			light: {
				error: "#ec5f59",
				info: "#4994ec",
				primary: "#1d376f",
				secondary: "#d4eff4",
				while: "#FFF",
				muted: "#8f9a86",
				success: "#67ad5b",
				warning: "#ec9235",
				disabled: "#bfbfbf",
				"light-gray": "#f5f5f5",
				"boston-blue": "#39839e",
				"call-green": "#23b100",
				"call-red": "#ff0000",
				"chat-primary": "#008BF0",
				"chat-secondary": "#ffffff",
				"turquoise-light": "#04B6CC",
				"turquoise-dark": "#0085A1",
				"turquoise-more-light": "#CDF0F5"
			}
		}
	}
});

Vue.use(Vuetify);

export default vuetify;
