import {
	ALERT_HIDDEN,
	EMAILS_TAB,
	NOTES_TAB,
	SIGNATURE_OPTION,
	TEXTO_TAB
} from "@/common/const";
import { getField, updateField } from "vuex-map-fields";

import { DISCONNECTED } from "@/composables/comms";
import axios from "axios";
import moment from "moment";

export default {
	getters: {
		loadingAllConversations: state => {
			return state.loadingAllConversations;
		},
		currentGroup: state => {
			return state.currentGroup;
		},

		client: state => {
			return state.client;
		},

		hasClient: state => {
			return state.hasClient;
		},

		averageRating: state => {
			return Math.round(state.averageRating);
		},

		surveyArray: state => {
			return state.surveyArray;
		},

		singleConversation: state => {
			return state.singleConversation;
		},

		allConversations: state => {
			return state.allConversations;
		},

		showReviews: state => {
			return state.showReviews;
		},

		filteredConversations: state => {
			return state.allConversations
				.filter(conversation => {
					if (!state.isContactsSearch || state.selectedConversationStatus === "inactive") {
						return conversation.state.current === state.selectedConversationStatus;
					}
					return true;
				})
				.sort((a, b) => {
					if (a.lastMessage && a.lastMessage.dateCreated && b.lastMessage && b.lastMessage.dateCreated) {
						return b.lastMessage.dateCreated - a.lastMessage.dateCreated;
					} else {
						return b.dateUpdated - a.dateUpdated;
					}
				});
		},

		notes: state => {
			return state.notes;
		},

		messages: state => {
			return state.messages;
		},

		allMessages: state => {
			return state.allMessages;
		},

		selectedConversationStatus: state => {
			return state.selectedConversationStatus;
		},

		isInitializing: state => {
			return state.isInitializing;
		},

		connectionState: state => {
			return state.connectionState;
		},

		hasUnreadSurveys: state => {
			return !!state.singleConversation.channelState.attributes?.hasUnreadSurveys
				? state.hasUnreadSurveys && !!state.singleConversation.channelState.attributes?.hasUnreadSurveys
				: !!state.singleConversation.channelState.attributes?.hasUnreadSurveys;
		},

		currentParticipant: (state) => {
			return state.currentParticipant;
		},

		isInboundMessage: (state, getters, rootState, rootGetters) => (message) => {
			if (message?.state?.attributes?.isCall) {
				return message.state.attributes.direction === "inbound";
			}

			if (message?.state?.attributes?.type === "email") {
				return message.state.attributes.fromClient;
			}

			return rootGetters["clinic/isFrance"]
				? message.direction == "inbound"
				: message?.author && /^\+\d+/i.test(message?.author);
		},

		hasNotifications: (state, getters, rootState, rootGetters) => (conversation) => {
			return rootGetters["clinic/isFrance"]
				? conversation.hasNotification
				: !!conversation.channelState.attributes.hasNotification;
		},

		hasPendingNotifications: (state, getters, rootState, rootGetters) => {
			if (!rootGetters["login/connected"]) return false;

			return rootGetters["clinic/isFrance"]
				? getters.allConversations.some(conversation => conversation.hasNotification)
				: getters.allConversations.some(conversation => conversation.channelState.attributes.hasNotification);
		},

		hasDisplayedConversation:  (state, getters ) => {
			return !!getters.allConversations.filter(conversation => conversation.active).length;
		},

		selectedAppointmentMessage: state => {
			return state.selectedAppointmentMessage;
		},

		contactAppointments: state => {
			return state.contactAppointments;
		},

		contactOldAppointments: state => {
			return state.contactOldAppointments;
		},

		contactPendingAppointments: state => {
			return state.contactPendingAppointments;
		},

		isTextoTab: state => {
			return state.activeSendBoxTab === TEXTO_TAB;
		},

		isEmailsTab: state => {
			return state.activeSendBoxTab === EMAILS_TAB;
		},

		isNotesTab: state => {
			return state.activeSendBoxTab === NOTES_TAB;
		},

		currentPage: state => {
			return state.pagination.currentPage;
		},

		signatureOption: state => {
			return state.signatureOption;
		},

		rendifyMessages: state => {
			return state.rendifyMessages;
		},

		newRendifyMessage: state => {
			return state.newRendifyMessage;
		},

		listStreamId: state => {
			return state.listStreamId;
		},

		loadingConversationRendify: state => {
			return state.loadingConversationRendify;
		},

		getField
	},
	mutations: {
		updateField,

		setCurrentGroup (state, currentGroup) {
			state.currentGroup = currentGroup;
		},

		setNewClientDisplay(state, client) {
			state.client = client;
		},

		setHasClient(state, hasClient) {
			state.hasClient = hasClient;
		},

		setAllConversation(state, allConversations) {
			state.allConversations = allConversations;
			state.singleConversation = {};
		},

		addSingleConversation(state, conversation) {
			const newConversationIndex = state.allConversations.findIndex(target => target.sid == conversation.sid);

			if (newConversationIndex == -1) {
				state.allConversations.push(conversation);
				return true;
			}

			return false;
		},

		setNewConversationDisplay(state, conversation) {
			conversation.active = true;
			state.singleConversation = conversation;
		},

		addSingleMessage(state, message) {
			state.messages = [...state.messages, message];
		},

		setFilteredMessages(state, messages) {
			state.messages = messages;
		},

		setAllMessages(state, allMessages) {
			state.allMessages = allMessages;
		},

		setNotes(state, newNotes) {
			state.notes = newNotes;
		},

		setSurveys(state, surveys) {
			state.surveyArray = surveys;
		},

		setNewRating(state, avg) {
			state.averageRating = avg;
		},

		setNewMessage(state, isNew) {
			state.newMessage = isNew;
		},

		emptyStatesOnConversationSwitch(state) {
			state.loadingConversation = true;
			state.notes = {};
			state.client = {};
			state.hasClient = false;
			state.surveyArray = [];
			state.averageRating = null;
			state.contactAppointments = [];
			state.contactOldAppointments = [];
			state.contactPendingAppointments = [];
			state.activeSendBoxTab = TEXTO_TAB;
		},

		toggleStatusFilter(state, status) {
			state.selectedConversationStatus = status;
		},

		destroySingleConversation(state) {
			state.singleConversation = {};
		},

		setIsInitializing(state, isInitializing) {
			state.isInitializing = isInitializing;
		},

		removeConversation(state, conversation) {
			const idx = state.allConversations.findIndex(
				(target) => target.sid === conversation.sid
			);

			if (idx > -1){
				state.allConversations.splice(idx);
			}
		},

		setConnectionState(state, connectionState) {
			state.connectionState = connectionState;
		},

		setCurrentParticipant(state, userGroup) {
			state.currentParticipant = userGroup;
		},

		removeActiveConversation(state) {
			state.allConversations.map(conversation => conversation.active = false);
		},

		hideDeletedConversation(state, deletedConversation) {
			removeConversation(state, deletedConversation);
		},

		setSelectedAppointmentMessage(state, selectedAppointmentMessage) {
			state.selectedAppointmentMessage = selectedAppointmentMessage;
		},

		setContactAppointments(state, allAppointments) {
			state.contactAppointments = allAppointments;
		},

		setOldContactAppointments(state, oldAppointments) {
			state.contactOldAppointments = oldAppointments;
		},

		setPendingContactAppointments(state, pendingAppointments) {
			state.contactPendingAppointments = pendingAppointments;
		},

		setCurrentPage(state, { page, selectedTab }) {
			state.pagination.currentPage[selectedTab] = page;
		},

		setCurrentParticipantEmail(state, { email, emailProvider, valid }) {
			if (state.currentParticipant) {
				state.currentParticipant = {
					...state.currentParticipant,
					email,
					emailProvider,
					valid
				};
			}
		},

		setSignatureOption(state, option) {
			state.signatureOption = option;
		},

		setSelectConversation(state, payload) {
			state.loadingConversationRendify = true;
			state.selectConversation = payload;
		},

		setRendifyMessages(state, payload) {
			state.loadingConversationRendify = false,
			state.rendifyMessages = payload;
		},

		setNewRendifyMessage(state, payload) {
			state.newRendifyMessage = payload;
		},

		setListStreamId(state, payload) {
			state.listStreamId = payload;
		},

		setLoadingConversationRendify (state, payload) {
			state.loadingConversationRendify = payload;
		}
	},

	actions: {
		async getAllNotes(context, id) {
			const { data: allNotes } = await axios.get(`/clients/${id}/notes`);

			allNotes.sort((a, b) => new Date(b.date) - new Date(a.date))
				.sort((a, b) => b.pin - a.pin);

			if (allNotes.length) {
				context.commit("setNotes", allNotes);
			}
		},

		// async getAllSurveys(context, id) {
		// 	try {
		// 		const { data: allSurveys } = await axios.get(`/clients/${id}/surveys`);

		// 		if (allSurveys.clientSurveys) {
		// 			context.commit("setSurveys", allSurveys.clientSurveys);

		// 			const unreadSurveyArray = allSurveys.clientSurveys.filter(survey => !survey.followupDone);

		// 			if (unreadSurveyArray.length) context.state.hasUnreadSurveys = true;
		// 		}
		// 	} catch (error) {

		// 	}

		// },

		// async getContactAppointments(context, id) {
		// 	try {

		// 		const { data: allAppointments } = await axios.get(`clients/${id}/appointments`);

		// 		let oldAppointments = [];

		// 		let pendingAppointments= [];

		// 		allAppointments.map(appointment => {
		// 			const appointmentDate = moment(appointment.start, "YYYY-MM-DD").format("YYYY-MM-DD");
		// 			const currentDate = moment().format("YYYY-MM-DD");

		// 			if (moment(appointmentDate).isBefore(currentDate)) oldAppointments.push(appointment);
		// 			else pendingAppointments.push(appointment);
		// 		});

		// 		if (allAppointments.length) context.commit("setContactAppointments", allAppointments);
		// 		if (oldAppointments.length) context.commit("setOldContactAppointments", oldAppointments);
		// 		if (pendingAppointments.length) context.commit("setPendingContactAppointments", pendingAppointments);
		// 	} catch (error) {

		// 	}
		// },

		async displayConversation({ state, commit, getters, dispatch }, sid) {
			if (state.loadingConversation) return;

			try {
				if (getters.filteredConversations.length) {
					const requireRefresh = getters.singleConversation?.sid;

					if (requireRefresh)	{
						commit("emptyStatesOnConversationSwitch");
					}

					if (getters.singleConversation.friendlyName) {
						getters.singleConversation.active = false;
					}

					if (sid === "first") {
						commit("setNewConversationDisplay", getters.filteredConversations[0]);
					} else {
						commit("setNewConversationDisplay", getters.filteredConversations.find(conversation => conversation.sid === sid) || getters.filteredConversations[0]);
					}

					try {
						const { data: client } = await axios.get(`clients/${getters.singleConversation.friendlyName}`);

						commit("setNewClientDisplay", client);
						commit("setHasClient", true);
						dispatch("getAllNotes", client.id);
						//  dispatch("getAllSurveys", client.id);
						//  dispatch("getContactAppointments", client.id);
					} catch (err) {
						commit("setHasClient", false);
					}

					if (getters.singleConversation?.friendlyName) {
						if (requireRefresh || sid === "first")	{
							await dispatch("loadMessages");
						}

						if (getters.singleConversation?.channelState?.attributes?.hasIncompleteFollowup) {
							dispatch("clearIncompleteFollowup");
						}

						state.twilioMessagePage = undefined;
					}
				} else {
					commit("destroySingleConversation");
				}
			} catch (err) {
				throw err;
			} finally {
				state.loadingConversation = false;
			}
		},

		async loadMessages({ commit, getters, state }) {
			state.messages = [];
			const messages = await getters.singleConversation.getMessages();
			const filteredMessages = messages.items.filter(message => !message.attributes.isFlowMessage);

			commit("setFilteredMessages", filteredMessages);
			commit("setAllMessages", messages.items);
		},

		async clearIncompleteFollowup({ getters }) {
			getters.singleConversation.updateAttributes({
				...getters.singleConversation.channelState.attributes,
				hasIncompleteFollowup: false
			});
		},

		async fetchMoreMessages({ commit, getters, state }) {
			if (state.twilioMessagePage === undefined) {
				state.twilioMessagePage = await getters.singleConversation.getMessages();
			}
			if (state.twilioMessagePage.hasPrevPage) {
				const previousPage = await state.twilioMessagePage.prevPage();

				commit("setFilteredMessages", [...previousPage.items, ...state.messages]);
				state.twilioMessagePage = previousPage;
			}
		},

		async clearNotifications({ getters }) {
			const lastMessageIndex = getters.allMessages.length ? getters.allMessages.length - 1 : 0;

			getters.singleConversation.updateLastReadMessageIndex(lastMessageIndex);

			getters.singleConversation.updateAttributes({
				...getters.singleConversation.channelState.attributes,
				hasNotification: false,
				isAppointmentMicrosite: false,
				isInformationMicrosite: false
			});
		},

		async createConversationPayload({ rootGetters }, conversationPayload) {
			const { to, message } = conversationPayload;

			return {
				// When no from and identity sent. The main conversation group is used
				// from: twilioInfo.phoneNumber,
				// identity: twilioInfo.identity,
				to: to.replace(/\s/g, ""),
				clinicId: rootGetters["login/profile"].clinic,
				...message && {
					body: message
				}
			};
		},

		async markAppointmentMicrositeConfirmed({ state }) {
			if (state.selectedAppointmentMessage) {
				state.selectedAppointmentMessage.updateAttributes({
					...state.selectedAppointmentMessage.attributes,
					isConfirmed: true
				});
			}
		}
	},

	namespaced: true,
	state: () => ({
		allConversations: [],
		filteredConversations: [],
		singleConversation: {},
		client: {},
		hasClient: false,
		notes: [],
		messages: [],
		selectedConversationStatus: "active",
		parentDialog: false,
		averageRating: null,
		surveyArray: [],
		followupCheckbox: true,
		allMessages: [],
		loadingConversation: false,
		loadingConversationRendify: false,
		showReviews: false,
		twilioMessagePage: undefined,
		appointmentUI: false,
		appointmentIdModify: undefined,
		appointmentRequest: false,
		newMessage : false,
		hasUnreadSurveys : false,
		audioNotificationsEnabled : false,
		isInitializing: false,
		connectionState: DISCONNECTED,
		appointmentUpdate: false,
		selectedContactsTab: 0,
		currentParticipant: null,
		isAttachmentSent: false,
		openContactsDrawer: false,
		sendContentOpen: false,
		desktopNotificationsEnabled: false,
		browserNotificationsDisabled: false,
		showNotificationsAlert: ALERT_HIDDEN,
		toggleStatusLoading: false,
		signatureOption: SIGNATURE_OPTION.NO_SIGNATURE,
		isContactsSearch: false,
		contactsSearchValue: undefined,
		awaitingConversationCreation: false,
		focusSendMessageInput: false,
		contactAppointments: [],
		contactOldAppointments: [],
		contactPendingAppointments: [],
		activeSendBoxTab: TEXTO_TAB,
		followupCheckbox: false,
		currentGroup: "",
		pagination: {
			currentPage: {
				"0": 1,
				"1": 1
			}
		},
		selectConversation: undefined,
		rendifyMessages: undefined,
		newRendifyMessage: true,
		listStreamId: []
	})
};
