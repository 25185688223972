<template>
	<svg
		width="30"
		height="30"
		viewBox="0 0 32 33"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M10.6667 16.4998H21.3333M20 11.1665H22.6667C25.6122 11.1665 28 13.5543 28 16.4998C28 19.4454 25.6122 21.8332 22.6667 21.8332H20M12 11.1665H9.33333C6.38781 11.1665 4 13.5543 4 16.4998C4 19.4454 6.38781 21.8332 9.33333 21.8332H12"
			stroke="#123873"
			stroke-width="2"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
	</svg>
</template>
<script>
export default {
	name:"LinkIcon"
};
</script>