<template>
<svg
width="20"
height="20"
viewBox="0 0 20 20"
fill="none"
xmlns="http://www.w3.org/2000/svg"
>
<g clip-path="url(#clip0_3443_9603)">
<path d="M9.99967 5.41667C13.158 5.41667 15.9747 7.19167 17.3497 10C15.9747 12.8083 13.1663 14.5833 9.99967 14.5833C6.83301 14.5833 4.02467 12.8083 2.64967 10C4.02467 7.19167 6.84134 5.41667 9.99967 5.41667ZM9.99967 3.75C5.83301 3.75 2.27467 6.34167 0.833008 10C2.27467 13.6583 5.83301 16.25 9.99967 16.25C14.1663 16.25 17.7247 13.6583 19.1663 10C17.7247 6.34167 14.1663 3.75 9.99967 3.75ZM9.99967 7.91667C11.1497 7.91667 12.083 8.85 12.083 10C12.083 11.15 11.1497 12.0833 9.99967 12.0833C8.84968 12.0833 7.91634 11.15 7.91634 10C7.91634 8.85 8.84968 7.91667 9.99967 7.91667ZM9.99967 6.25C7.93301 6.25 6.24967 7.93333 6.24967 10C6.24967 12.0667 7.93301 13.75 9.99967 13.75C12.0663 13.75 13.7497 12.0667 13.7497 10C13.7497 7.93333 12.0663 6.25 9.99967 6.25Z" fill="#04B6CC" />
<path d="M0 0L21 21" stroke="currentColor" />
</g>
<defs>
<clipPath id="clip0_3443_9603">
<rect width="20" height="20" fill="white" />
</clipPath>
</defs>
</svg>
</template>
<script>
export default {
	name:"EyeOffIcon"
};
</script>