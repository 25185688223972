<template>
	<svg
		width="47"
		height="47"
		viewBox="0 0 47 47"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
	<path
		d="M38.25 8.75L32 15M38.25 8.75L44.5 2.5M38.25 8.75L44.5 15M38.25 8.75L32 2.5M31.35 31.7L33.4985 28.8353C34.0785 28.0621 35.1284 27.8142 35.9929 28.2465L43.1337 31.8168C43.9404 32.2202 44.3773 33.1135 44.2004 33.9979L42.3216 43.3922C42.1346 44.3271 41.3122 45.006 40.3598 44.9628C20.3395 44.0551 2.94487 26.6605 2.03719 6.64017C1.99402 5.68779 2.67292 4.86542 3.60777 4.67845L13.0021 2.79959C13.8865 2.62271 14.7798 3.05963 15.1832 3.86632L18.7535 11.0071C19.1858 11.8716 18.9379 12.9215 18.1647 13.5015L15.3 15.65C14.7964 16.0277 14.482 16.6268 14.6238 17.2401C15.7799 22.2411 24.7589 31.2201 29.7599 32.3762C30.3733 32.518 30.9723 32.2036 31.35 31.7Z"
		stroke="white"
		stroke-width="4"
		stroke-linecap="round"
		stroke-linejoin="round"
	/>
	</svg>
</template>

<script>
export default {
	name: "CallFailedIcon"
};
</script>

<style lang="scss" scoped>

</style>