import { updateField, getField } from "vuex-map-fields";

const getDefaultState = () => ({
	selectedSection: {},
	checkedSections: [],
	contentDialog: false,
	clientDialog: true,
	client: {
		name: undefined,
		phoneNumber: undefined,
		birthday: undefined,
		fullName: undefined
	},
	sections: [],
	post: {},
	openedRoot: undefined,
	rootSections: [],
	newSections: [],
	modelMode: false
});

export default {
	getters: {
		getField,

		rootSections: state => {
			return state.rootSections;
		},
		newSections: state => state.newSections,
		modelMode: state => state.modelMode
	},

	mutations: {
		updateField,

		setNewTree(state, tree) {
			return state.rootSections = tree;
		},
		resetState(state) {
			Object.assign(state, getDefaultState());
		},
		resetClient(state) {
			state.client = {
				name: undefined,
				phoneNumber: undefined,
				birthday: undefined,
				fullName: undefined
			};
		},
		setNewSections: (state, section) => {
			state.newSections.push(section);
			state.newSections = [...new Set(state.newSections)];
		},
		resetNewSections: state => state.newSections = [],
		setModelMode: (state, payload) => state.modelMode = payload
	},
	actions: {
		listToTree({ commit, state, rootGetters }, list) {
			const treeMap = {}, roots = [];

			const updatedSections = rootGetters["article/allSections"].map(section=>{
				const found = state.newSections.find(elem => elem.id == section.id);

				if (found) return { ...section, parent: found.parent };

				return section;
			});

			commit("article/updateAllSections", updatedSections, { root: true });

			list.map((section, index) => {
				treeMap[section.id] = index;
				section.children = [];
				
				if (section.parent !== "root") {
					list[treeMap[section.parent]] && list[treeMap[section.parent]].children.push(section);
				} else {
					roots.push(section);
				}
			});

			commit("setNewTree", roots);
		}
	},
	namespaced: true,

	state: () => ({
		selectedSection: {},
		checkedSections: [],
		contentDialog: false,
		clientDialog: true,
		isClientSelected: false,
		client: {
			name: undefined,
			phoneNumber: undefined,
			birthday: undefined,
			fullName: undefined
		},
		sections: [],
		post: {},
		openedRoot: undefined,
		rootSections: [],
		newSections: [],
		modelMode: false,
		displayNotes: false
	})
};