<template>
<svg
width="20"
height="14"
viewBox="0 0 20 14"
fill="none"
xmlns="http://www.w3.org/2000/svg"
>
<path d="M9.99967 2.41667C13.158 2.41667 15.9747 4.19167 17.3497 7C15.9747 9.80833 13.1663 11.5833 9.99967 11.5833C6.83301 11.5833 4.02467 9.80833 2.64967 7C4.02467 4.19167 6.84134 2.41667 9.99967 2.41667ZM9.99967 0.75C5.83301 0.75 2.27467 3.34167 0.833008 7C2.27467 10.6583 5.83301 13.25 9.99967 13.25C14.1663 13.25 17.7247 10.6583 19.1663 7C17.7247 3.34167 14.1663 0.75 9.99967 0.75ZM9.99967 4.91667C11.1497 4.91667 12.083 5.85 12.083 7C12.083 8.15 11.1497 9.08333 9.99967 9.08333C8.84968 9.08333 7.91634 8.15 7.91634 7C7.91634 5.85 8.84968 4.91667 9.99967 4.91667ZM9.99967 3.25C7.93301 3.25 6.24967 4.93333 6.24967 7C6.24967 9.06667 7.93301 10.75 9.99967 10.75C12.0663 10.75 13.7497 9.06667 13.7497 7C13.7497 4.93333 12.0663 3.25 9.99967 3.25Z" fill="currentColor" />
</svg>
</template>
<script>
export default {
	name:"EyeIcon"
};
</script>