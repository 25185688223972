<template>
<svg
width="41"
height="40"
viewBox="0 0 41 40"
fill="none"
xmlns="http://www.w3.org/2000/svg"
>
<g clip-path="url(#clip0_3034_6900)">
<path d="M4.30469 4H36.3047C37.4047 4 38.3047 4.9 38.3047 6V34C38.3047 35.1 37.4047 36 36.3047 36H4.30469C3.20469 36 2.30469 35.1 2.30469 34V6C2.30469 4.9 3.20469 4 4.30469 4ZM34.3047 32V14H6.30469V32H34.3047ZM8.30469 16V18H14.3047V16H8.30469ZM16.3047 16V22H32.3047V16H16.3047ZM8.30469 24V26H14.3047V24H8.30469ZM16.3047 24V30H32.3047V24H16.3047Z" fill="currentcolor" />
</g>
<defs>
<clipPath id="clip0_3034_6900">
<rect width="40" height="40" fill="white" transform="translate(0.304688)" />
</clipPath>
</defs>
</svg>
</template>
<script>
export default {
	name:"ReviewsIcon",
                
	props:{
		color:{
			type:String,
			default:"#fff"
		}
	}
};
</script>