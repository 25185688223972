<template>
<svg
width="32"
height="32"
viewBox="0 0 32 32"
fill="none"
xmlns="http://www.w3.org/2000/svg"
>
<path
d="M22.667 15.9998C22.667 16.7362 23.2639 17.3332 24.0003 17.3332C24.7367 17.3332 25.3337 16.7362 25.3337 15.9998C25.3337 15.2635 24.7367 14.6665 24.0003 14.6665C23.2639 14.6665 22.667 15.2635 22.667 15.9998Z"
stroke="black"
stroke-opacity="0.8"
stroke-width="2"
stroke-linecap="round"
stroke-linejoin="round"
/>
<path
d="M14.667 15.9998C14.667 16.7362 15.2639 17.3332 16.0003 17.3332C16.7367 17.3332 17.3337 16.7362 17.3337 15.9998C17.3337 15.2635 16.7367 14.6665 16.0003 14.6665C15.2639 14.6665 14.667 15.2635 14.667 15.9998Z"
stroke="black"
stroke-opacity="0.8"
stroke-width="2"
stroke-linecap="round"
stroke-linejoin="round"
/>
<path
d="M6.66699 15.9998C6.66699 16.7362 7.26395 17.3332 8.00033 17.3332C8.73671 17.3332 9.33366 16.7362 9.33366 15.9998C9.33366 15.2635 8.73671 14.6665 8.00033 14.6665C7.26395 14.6665 6.66699 15.2635 6.66699 15.9998Z"
stroke="black"
stroke-opacity="0.8"
stroke-width="2"
stroke-linecap="round"
stroke-linejoin="round"
/>
</svg>
</template>
<script>
export default {
	name:"MoreIcon",

	props:{
		color:{
			type:String,
			default:"#000"
		}
	}
};
</script>