<template>
	<modal
		v-model="showDialog"
		:width="$vuetify.breakpoint.xl ? '60%' : '70%'"
		:title="$t('admin-panel')"
		type="question"
		:is-cta-disable="isCtaDisable"
		:cancel-text="$t('cancel')"
		:confirm-text="$t('save')"
		@cancel="closeDialog"
		@confirm="updateStatus"
	>
		<v-row no-gutters class="px-2">
			<admin-form
				v-model="validForm"
				:form="form"
				:admin-dialog="adminDialog"
				@updateValidation="updateValidation"
				@onDialogClose="adminDialog = false"
			/>
		</v-row>
	</modal>
</template>

<script>
import { mapGetters } from "vuex";
import AdminForm from "./AdminForm";
import Modal from "@/components/common/Modal";

export default {
	name: "AdminDialog",
	components: { AdminForm, Modal },

	props: {
		value: {
			type: Boolean,
			default: false
		}
	},

	data() {
		return {
			validForm: false,
			loading: false,
			form: {
				id: undefined,
				alertBeforeMins: undefined,
				banner: undefined,
				bannerText: undefined,
				dismissable: undefined,
				durationMins: undefined,
				enabled: undefined,
				nextMaintenance: undefined,
				overlay: undefined,
				sourceTwilio: undefined,
				status: undefined
			},
			adminDialog: false
		};
	},

	computed: {
		...mapGetters("login", ["profile"]),

		showDialog: {
			get() {
				return this.value;
			},

			set(value) {
				this.$emit("input", value);
			}
		},

		isCtaDisable() {
			return {
				cancel: this.loading,
				confirm: !this.validForm
			};
		}
	},

	watch: {
		fullPath(newPath) {
			this.form.url = newPath;
		}
	},

	async mounted() {
		try {
			this.form = (await this.axios.get("status")).data;
		} catch (err) {}
	},

	methods: {
		async updateStatus() {
			this.loading = true;
			try {
				await this.axios.put("status", this.form);

				this.$store.commit("alerts/add", {
					type: "success",
					message: this.$t("status-changed"),
					timeout: true
				});

				this.closeDialog();
				window.location.reload(true);
			} catch (err) {
				this.$store.commit("alerts/add", {
					type: "error",
					message: this.$t("error.change-status"),
					timeout: true
				});
			} finally {
				this.loading = false;
			}
		},

		closeDialog() {
			this.adminDialog = true;
			this.form = {
				...this.form
			};
			this.$emit("closeDialog");
		},

		updateValidation(isFormValid) {
			this.validForm = isFormValid;
		}
	}
};
</script>

<style scoped>
.content-height {
	height: 45vh;
	overflow-y: auto !important;
}
</style>