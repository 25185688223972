<template>
	<v-card flat class="pr-0 pl-5 d-flex flex-column justify-space-between" width="100%">
		<span class="text-h6 font-weight-bold primary--text">{{ $t("support-instruction") }}</span>

		<v-form
			v-model="validForm"
			class="text-center flex-1"
		>
			
				<v-col>
					<v-row >
					<v-col
						cols="6"
						class="py-0 pl-0"
					>
						<v-text-field
							v-model="inputForm.name"
							class="mt-5 textfield-wrap rounded-3"
							solo
							color="primary"
							dense
							:rules="[rules.required(form.name, 'name')]"
							background-color="light-primary"
						>
					<template #label>
						<span class="primary--text">{{ $t('last-name') }}</span>
					</template>
					</v-text-field>
					</v-col>

					<v-col
						cols="6"
						class="py-0 pr-0"
					>
						<v-text-field
							v-model="inputForm.email"
							class="mt-5 textfield-wrap rounded-3"
							solo
							hide-details
							color="primary"
							dense
							:rules="[rules.required(form.email, 'email'), rules.email]"
							background-color="light-primary"
						>
							<template #label>
								<span class="primary--text">{{ $t('email') }}</span>
							</template>
						</v-text-field>
					</v-col>
				</v-row>

				<v-row class="mt-n7">
					<v-col cols="6" class="py-0 pl-0">
						<v-row no-gutters>
							<v-col>
								<v-text-field
									v-model="inputForm.url"
									class="mt-5 textfield-wrap rounded-3"
									solo
									color="primary"
									dense
									background-color="light-primary"
									hide-details
								>
								<template #label>
								<span class="primary--text">{{ $t('url') }}</span>
							</template>
								<template #append>
								<v-tooltip bottom>
								<template #activator="{ on, attrs }">
									<v-icon
										color="primary"
										class="cursor-pointer"
										v-bind="attrs"
										v-on="on"
									>
										mdi-information-outline
									</v-icon>
								</template>
								<span>{{ $t("url-tooltip") }}</span>
							</v-tooltip>
							</template>
</v-text-field>
							</v-col>
</v-row>
					</v-col>

					<v-col cols="6" class="py-0 pr-0">
						<v-row no-gutters>
							<v-file-input
							    v-model="imgFileInput"
								class="mt-5"
								flat
						        hide-details
								solo
								accept="image/png, image/jpeg, image/svg+xml"
								clearable
								
								
							>
							<template #label>
								<span class="primary--text">{{$t('screenshot')}}</span>
							</template>
							<template #append>
								
						
							<v-tooltip bottom>
								<template #activator="{ on, attrs }">
									<v-icon
										color="primary"
										class="cursor-pointer"
										v-bind="attrs"
										v-on="on"
									>
										mdi-information-outline
									</v-icon>
								</template>
								<span>{{ $t("screenshot-tooltip") }}</span>
							</v-tooltip>
						</template>
						</v-file-input>
</v-row>
					</v-col>
				</v-row>
				</v-col>
					
			
				

				<v-col  class="py-0 pr-0 pl-0 mt-5">
					<v-textarea
						v-model="inputForm.description"
						solo
						color="primary"
						height="18vh"
						auto-grow
						class=" mb-5 textarea-wrap rounded-3"
						no-resize
						hide-details
						:rules="[rules.required(form.description, 'description')]"
						background-color="light-primary"
					>
					<template #label>
								<span class="primary--text">{{ $t('problem-description') }}</span>
							</template>
				</v-textarea>
				</v-col>
			
		</v-form>
	</v-card>
</template>

<script>
import { RequiredValidator, EmailValidator } from "@/validators";
import { mapGetters } from "vuex";

export default {
	name: "SupportForm",

	props:{
		value : {
			type : Boolean,
			default : false
		},

		fullPath :  {
			type : String,
			default : undefined
		},

		form : {
			type : Object,
			default : undefined
		},

		supportDialog : {
			type : Boolean,
			default : false
		}
	},

	data() {
		return {
			validForm: false,
			imgFileInput: undefined,
			rules: {
				email: EmailValidator,
				required: RequiredValidator
			},
			inputForm: {}
		};
	},

	computed: {
		...mapGetters("login", ["profile"]),
		...mapGetters("clinic", ["isFrance"])
	},

	watch: {
		form(updatedForm) {
			this.inputForm = updatedForm;
		},

		validForm (isFormValid) {
			this.$emit("updateValidation", isFormValid);
		},

		imgFileInput(newImgFileInput) {
			if (newImgFileInput) {
				const reader = new FileReader();

				reader.readAsDataURL(this.imgFileInput);
				reader.onload = () => {
					this.inputForm.screenshot = reader.result;
				};
			}
		},

		supportDialog(isCloseDialog) {
			if (isCloseDialog) {
				this.imgFileInput = undefined;
				this.$emit("onDialogClose");
			}
		}
	},

	mounted() {
		this.inputForm = this.form;
	}
};
</script>
<style lang=scss scoped>
::v-deep .v-text-field__slot input{
	color:var(--v-primary-base) !important
}
::v-deep .v-text-field__slot textarea{
	color:var(--v-primary-base) !important
}
::v-deep  .textarea-wrap .v-input__control {
	min-height: 100% !important

}
::v-deep .v-file-input{
	width: 100%;
	height: 48px !important;
	border-radius: 8px !important;
	box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
::v-deep  .textfield-wrap .v-input__control {
	min-height: 48px !important;

}
</style>