<template>
<svg
width="32"
height="32"
viewBox="0 0 32 32"
fill="none"
xmlns="http://www.w3.org/2000/svg"
>
<g clip-path="url(#clip0_2766_33078)">
<path d="M3.19961 3.2002H28.7996C29.6796 3.2002 30.3996 3.9202 30.3996 4.8002V27.2002C30.3996 28.0802 29.6796 28.8002 28.7996 28.8002H3.19961C2.31961 28.8002 1.59961 28.0802 1.59961 27.2002V4.8002C1.59961 3.9202 2.31961 3.2002 3.19961 3.2002ZM27.1996 25.6002V11.2002H4.79961V25.6002H27.1996ZM6.39961 12.8002V14.4002H11.1996V12.8002H6.39961ZM12.7996 12.8002V17.6002H25.5996V12.8002H12.7996ZM6.39961 19.2002V20.8002H11.1996V19.2002H6.39961ZM12.7996 19.2002V24.0002H25.5996V19.2002H12.7996Z" fill="currentcolor" fill-opacity="0.8" />
</g>
<defs>
<clipPath id="clip0_2766_33078">
<rect width="32" height="32" fill="black" />
</clipPath>
</defs>
</svg>
</template>
<script>
export default {
	name:"ReviewIcon",

	props:{
		color:{
			type:String,
			default:"#fff"
		}
	}
};
</script>