<template>
	<svg
		width="28"
		height="28"
		viewBox="0 0 32 33"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
	<path
		d="M9.33366 8.5H22.3337C26.1997 8.5 29.3337 11.634 29.3337 15.5C29.3337 19.366 26.1997 22.5 22.3337 22.5H7.33366C4.75633 22.5 2.66699 20.4107 2.66699 17.8333C2.66699 15.256 4.75633 13.1667 7.33366 13.1667H22.3337C23.6223 13.1667 24.667 14.2113 24.667 15.5C24.667 16.7887 23.6223 17.8333 22.3337 17.8333H9.33366"
		stroke="#123873"
		stroke-width="2"
		stroke-linecap="round"
		stroke-linejoin="round"
	/>
	</svg>
</template>
<script>
export default {
	name:"PaperClipIcon"

};
</script>