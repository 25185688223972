<template>
<svg
width="41"
height="40"
viewBox="0 0 41 40"
fill="none"
xmlns="http://www.w3.org/2000/svg"
>
<path d="M20.6051 18.9167C23.3343 18.9171 25.5596 16.7289 25.6051 14.0001V12.1334C25.6052 11.0255 25.1606 9.96395 24.371 9.18685C23.5814 8.40975 22.5128 7.98218 21.4051 8.00005H19.8218C17.5455 8.00923 15.7051 9.8571 15.7051 12.1334V14.0001C15.7494 16.6904 17.9149 18.8633 20.6051 18.9167Z" fill="currentcolor" />
<path d="M38.2717 27.5167L38.0717 25C37.8556 23.7362 36.7705 22.8051 35.4884 22.7834H28.2884C28.178 22.7662 28.0655 22.7662 27.9551 22.7834C27.3163 22.0659 26.399 21.6589 25.4384 21.6667H15.7884C14.7711 21.6623 13.8075 22.1226 13.1717 22.9167C12.8994 22.8171 12.6117 22.7664 12.3217 22.7667H5.12174C3.83439 22.7899 2.74743 23.7296 2.53841 25L2.33841 27.6C2.17092 28.3974 2.36338 29.228 2.86446 29.8704C3.36554 30.5128 4.1243 30.9017 4.93841 30.9334H12.5217C12.6713 30.9506 12.8222 30.9506 12.9717 30.9334C13.6328 31.663 14.5705 32.0805 15.5551 32.0834H25.6717C26.6263 32.0788 27.5367 31.6809 28.1884 30.9834H35.6717C36.5154 30.9601 37.3023 30.553 37.8087 29.8778C38.3151 29.2026 38.4856 28.3332 38.2717 27.5167Z" fill="currentcolor" />
<path d="M31.9717 20.1834C33.9968 20.1834 35.6384 18.5418 35.6384 16.5167V15.1C35.6387 14.2677 35.3024 13.4705 34.7061 12.8898C34.1097 12.3091 33.3038 11.9942 32.4717 12.0167H31.3051C29.6022 12.0167 28.2217 13.3972 28.2217 15.1V16.5167C28.2215 17.5038 28.6192 18.4493 29.325 19.1394C30.0307 19.8294 30.9849 20.2058 31.9717 20.1834Z" fill="currentcolor" />
<path d="M8.72175 20.1834C10.7468 20.1834 12.3884 18.5418 12.3884 16.5167V15.1001C12.3884 13.3972 11.008 12.0167 9.30508 12.0167H8.13841C6.43554 12.0167 5.05508 13.3972 5.05508 15.1001V16.5167C5.05508 18.5418 6.6967 20.1834 8.72175 20.1834Z" fill="currentcolor" />
</svg>
</template>
<script>
export default {
	name:"ContactsIcon",
        
	props:{
		color:{
			type:String,
			default:"#fff"
		}
	}
};
</script>