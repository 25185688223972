<template>
	<svg
		width="26"
		height="26"
		viewBox="0 0 26 26"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M13 11.6667V18.3333M13 25C6.37258 25 1 19.6274 1 13C1 6.37258 6.37258 1 13 1C19.6274 1 25 6.37258 25 13C25 19.6274 19.6274 25 13 25ZM13.0664 7.66667V7.8L12.9336 7.80026V7.66667H13.0664Z"
			stroke="#123873"
			stroke-width="2"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
</svg>
</template>

<script>
export default {
	name: "InfoIcon"
};
</script>

<style lang="scss" scoped>

</style>