<template>
	<v-dialog
		v-model="show"
		:max-width="modalMaxWidth"
		:persistent="isPersistent"
		:width="modalWidth"
		@click:outside="isPersistent ? () => {} : handleClose()"
	>
		<v-card class="py-6 px-3">
			<v-row v-if="!hideHeader" no-gutters class="flex flex-nowrap">
				<v-card-text class="py-0 text-truncate">
					<v-card-subtitle class="text-h6 font-weight-bold py-0 pl-0 title text-truncate text-center">
						<span v-if="modalTitle" :class="titleClass">{{ modalTitle }}</span>
					</v-card-subtitle>

					<v-card-subtitle v-if="modalSubTitle" class="pa-0">
						{{ modalSubTitle }}
					</v-card-subtitle>
				</v-card-text>
				<slot name="sentTime" />
				<v-btn
					v-if="modalType !== 'question' || showCloseButton"
					icon
					color="secondary"
					class="mr-2"
					:disabled="loading"
					@click="handleClose"
				>
					<v-icon size="30"> mdi-close </v-icon>
				</v-btn>
			</v-row>

			<v-card flat>
				<slot name="details" />
				<v-divider v-if="!hideHeader" class="mb-4 mt-2 mx-4" />

				<v-sheet v-if="modalLoading" class="d-flex flex-column justify-center align-center py-12">
					<v-progress-circular :size="50" :width="7" color="primary" indeterminate />
				</v-sheet>

				<template v-else>
					<slot />

					<v-card-actions v-if="!isHideCta" class="pa-0 px-4 pt-4">
						<v-btn
							v-if="!!modalCancelText"
							outlined
							color="turquoise-light"
							:disabled="isCancelDisable"
							:loading="isCancelLoading"
							class="px-4 ml-auto text-capitalize rounded-1"
							@click="$emit('cancel')"
						>
							{{ modalCancelText }}
						</v-btn>

						<v-spacer v-if="showSpacer" />

						<v-btn
							v-if="!!modalExtraText"
							color="turquoise-light"
							:disabled="isExtraDisable"
							:loading="isExtraLoading"
							class="px-4 text-capitalize rounded-1 white--text"
							@click="$emit('extra')"
						>
							{{ modalExtraText }}
						</v-btn>

						<v-btn
							v-if="!!modalConfirmText"
							color="turquoise-light"
							:disabled="isConfirmDisable"
							:loading="isConfirmLoading"
							class="px-4 text-capitalize rounded-1 white--text"
							@click="$emit('confirm')"
						>
							<v-icon v-if="saveIcon" color="white" class="mr-2"> mdi-content-save </v-icon>

							{{ modalConfirmText }}
						</v-btn>
						<slot name="close" />
					</v-card-actions>
				</template>
			</v-card>
		</v-card>
	</v-dialog>
</template>

<script>
export default {
	name: "Modal",

	props: {
		persistent: {
			type: Boolean,
			default: false
		},

		showCloseButton: {
			type: Boolean,
			default: false
		},

		hideHeader: {
			type: Boolean,
			default: false
		},

		type: {
			type: String,
			default: ""
		},

		value: {
			type: Boolean,
			default: false
		},

		title: {
			type: String,
			default: ""
		},

		subTitle: {
			type: String,
			default: ""
		},

		width: {
			type: String,
			default: ""
		},

		height:{
			type: String,
			default: ""
		},

		maxWidth: {
			type: String,
			default: ""
		},

		loading: {
			type: Boolean,
			default: false
		},

		cancelText: {
			type: String,
			default: ""
		},

		confirmText: {
			type: String,
			default: ""
		},

		extraText: {
			type: String,
			default: ""
		},

		confirmLoading: {
			type: Boolean,
			default: false
		},

		cancelLoading: {
			type: Boolean,
			default: false
		},

		extraLoading: {
			type: Boolean,
			default: false
		},

		confirmDisabled: {
			type: Boolean,
			default: false
		},

		extraDisabled: {
			type: Boolean,
			default: false
		},

		isCtaDisable: {
			type: Object,
			default: () => {}
		},

		hideCta: {
			type: Boolean,
			default: false
		},

		showSaveIcon: {
			type: Boolean,
			default: false
		},

		message: {
			default: undefined,
			type: Object
		},

		showSpacer: {
      		type: Boolean,
      		default: true
    	},

		titleClass: {
			type: String,
			default: ""
		}
	},

	data() {
		return {};
	},

	computed: {
		show: {
			get() {
				return this.value;
			},

			set(value) {
				this.$emit("input", value);
			}
		},

		modalType() {
			return this.type;
		},

		modalTitle() {
			if (typeof this.title === "object") {
				return `<span style="${this.title.style}">${this.title.text}</span>`;
			} else {
				return this.title;
			}
		},

		modalSubTitle() {
			return this.subTitle;
		},

		modalWidth() {
			return this.width;
		},

		modalMaxWidth() {
			return this.maxWidth;
		},

		modalHeight() {
			return this.height;
		},

		modalCancelText() {
			return this.cancelText;
		},

		modalConfirmText() {
			return this.confirmText;
		},

		modalExtraText() {
			return this.extraText;
		},

		isConfirmDisable() {
			return this.isCtaDisable?.confirm || this.confirmDisabled;
		},

		isCancelDisable() {
			return this.isCtaDisable?.cancel;
		},

		isExtraDisable() {
			return this.isCtaDisable?.cancel;
		},

		modalLoading() {
			return this.loading;
		},

		isHideCta() {
			return this.hideCta;
		},

		isConfirmLoading() {
			return this.confirmLoading;
		},

		isCancelLoading() {
			return this.cancelLoading;
		},

		isExtraLoading() {
			return this.extraLoading;
		},

		saveIcon() {
			return this.showSaveIcon;
		},

		isPersistent() {
			return this.persistent;
		}
	},

	methods: {
		handleClose() {
			this.show = false;
			this.$emit("close");
			this.$emit("cancel", { isClosing: true });
		}
	}
};
</script>

<style>
.title {
	line-height: 36px !important;
}
</style>
