export default {
	install(Vue) {
		Vue.prototype.$capitalize = function (text) {
			return text.charAt(0).toUpperCase() + text.slice(1);
		};

		Vue.prototype.$plain = function (o) {
			return JSON.parse(JSON.stringify(o));
		};

		Vue.prototype.$baseUrl = function () {
			if (process.env.NODE_ENV === "development") return "http://localhost:8080";
			if (process.env.NODE_ENV === "staging") return "https://rc.inservio.app";
			if (process.env.NODE_ENV === "production") return "https://my.inservio.app";
		};
	}
};