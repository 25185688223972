import { getField, updateField } from "vuex-map-fields";

export default {
	getters: {
		getField,
		nav: (state) => state.nav
	},
	mutations: {
		disableNavigation(state) {
			state.nav = false;
		},
		enableNavigation(state) {
			state.nav = true;
		},
		disableMenu(state) {
			state.menu = false;
		},
		enableMenu(state) {
			state.menu = true;
		},
		setNav(state, payload) {
			state.nav = payload;
		},
		updateField
	},
	namespaced: true,
	state: () => ({
		nav: true,
		menu: true,
		pin: false,
		notePanel: false
	})
};
