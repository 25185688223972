import { Workbox } from "workbox-window";

if ("serviceWorker" in navigator) {
	// const wb = new Workbox(`${process.env.BASE_URL}service-worker.js`);

	// wb.addEventListener("waiting", () => {
	// 	let result = false;

	// 	// When embedding the widget, auto-update without asking
	// 	if (/(widget|microsite)/.test(`${window.location}`)) {
	// 		result = true;
	// 	} else {
	// 		result = window.confirm("inversio a été mis à jour. Actualiser maintenant ?");
	// 	}

	// 	if (result) {
	// 		wb.messageSkipWaiting();
	// 	}
	// });

	// wb.addEventListener("controlling", () => {
	// 	window.location.reload(true);
	// });

	// wb.register();
}
