<template>
<svg
width="41"
height="40"
viewBox="0 0 41 40"
fill="none"
xmlns="http://www.w3.org/2000/svg"
>
<path
d="M20.304 24.9998L25.304 19.9998M25.304 19.9998L20.304 14.9998M25.304 19.9998H6.9707M15.304 12.0808V12.0002C15.304 10.1333 15.304 9.1992 15.6673 8.48617C15.9869 7.85896 16.4965 7.34939 17.1237 7.02982C17.8367 6.6665 18.7709 6.6665 20.6377 6.6665H28.3044C30.1712 6.6665 31.1033 6.6665 31.8164 7.02982C32.4436 7.34939 32.9548 7.85896 33.2744 8.48617C33.6374 9.19851 33.6374 10.1315 33.6374 11.9947V28.0058C33.6374 29.869 33.6374 30.8006 33.2744 31.513C32.9548 32.1402 32.4436 32.6506 31.8164 32.9702C31.104 33.3332 30.1724 33.3332 28.3092 33.3332H20.6322C18.769 33.3332 17.836 33.3332 17.1237 32.9702C16.4965 32.6506 15.9869 32.1397 15.6673 31.5125C15.304 30.7994 15.304 29.8667 15.304 27.9998V27.9165"
stroke="currentColor"
stroke-width="2"
stroke-linecap="round"
stroke-linejoin="round"
/>
</svg>
</template>
<script>
export default {
	name:"LogoutIcon"
};
</script>