import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import alerts from "./alerts";
import appointment from "./appointment";
import article from "./article";
import blocGrid from "./blocGrid";
import clinic from "./clinic";
import contact from "./contact";
import conversation from "./conversation";
import documents from "./documents";
import consultation from "./consultation";
import groups from "./groups";
import login from "./login";
import onboarding from "./onboarding";
import ui from "./ui";
import voice from "./voice";

Vue.use(Vuex);

const persistedState = createPersistedState({
	paths: [
		"blocGrid.activePostType",
		"clinic.clinicData",
		"consultation.isClientSelected",
		"consultation.client",
		"conversation.audioNotificationsEnabled",
		"conversation.desktopNotificationsEnabled",
		"conversation.browserNotificationsDisabled",
		"conversation.showNotificationsAlert",
		"conversation.signatureOption",
		"conversation.currentGroup",
		"login",
		"ui"
	]
});

export default new Vuex.Store({
	modules: {
		alerts,
		appointment,
		article,
		blocGrid,
		clinic,
		consultation,
		contact,
		conversation,
		documents,
		groups,
		login,
		onboarding,
		ui,
		voice
	},
	plugins: [persistedState]
});
