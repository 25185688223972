<template>
	<svg
		width="28"
		height="28"
		viewBox="0 0 32 33"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path d="M16.0013 29.8332C23.3651 29.8332 29.3346 23.8636 29.3346 16.4998C29.3346 9.13604 23.3651 3.1665 16.0013 3.1665C8.6375 3.1665 2.66797 9.13604 2.66797 16.4998C2.66797 23.8636 8.6375 29.8332 16.0013 29.8332Z" stroke="#123873" stroke-width="2" stroke-linejoin="round" />
		<path
			d="M20.668 12.5V13.1667"
			stroke="#123873"
			stroke-width="2"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M11.332 12.5V13.1667"
			stroke="#123873"
			stroke-width="2"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M20.6654 21.1665C20.6654 21.1665 19.332 23.8332 15.9987 23.8332C12.6654 23.8332 11.332 21.1665 11.332 21.1665"
			stroke="#123873"
			stroke-width="2"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
	</svg>
</template>
<script>
export default {
	name:"EmoticonIcon"
};
</script>