<template>
  <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M9.30014 19.6211C7.72495 20.5305 6.93658 20.9851 6.2903 20.9172C5.72659 20.8579 5.21495 20.5621 4.88178 20.1035C4.49994 19.578 4.49994 18.6691 4.49994 16.8515L4.49994 7.14853C4.49994 5.3309 4.49994 4.42218 4.88178 3.89662C5.21495 3.43806 5.72659 3.14206 6.2903 3.08281C6.93658 3.01489 7.72495 3.46934 9.30014 4.37877L17.7001 9.22852C19.2753 10.1379 20.0628 10.5929 20.3271 11.1865C20.5576 11.7043 20.5576 12.296 20.3271 12.8138C20.0629 13.4072 19.2754 13.8619 17.7016 14.7705L9.30014 19.6211Z" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

    
        
        </template>
        
        <script>
        export default {
            name: "ArrowRightIcon"
        };
        </script>
        
        <style lang="scss" scoped>
        
        </style>
        