<template>
	<svg
		width="32"
		height="32"
		viewBox="0 0 32 32"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M12.6699 5.67629C12.265 4.66387 11.2844 4 10.194 4H6.52632C5.13107 4 4 5.1308 4 6.52604C4 18.3856 13.6144 28 25.474 28C26.8692 28 28 26.8688 28 25.4736L28.0006 21.8053C28.0006 20.7149 27.3369 19.7345 26.3245 19.3295L22.8092 17.9239C21.8998 17.5601 20.8644 17.7239 20.1119 18.3509L19.2047 19.1076C18.1452 19.9905 16.5862 19.9203 15.6109 18.945L13.0563 16.3881C12.081 15.4128 12.009 13.8551 12.8919 12.7956L13.6484 11.8884C14.2755 11.1359 14.4407 10.1002 14.0769 9.19079L12.6699 5.67629Z"
			stroke="currentcolor"
			stroke-opacity="0.8"
			stroke-width="2"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
	</svg>
</template>
<script>

export default {
	name:"CallIcon"

};
</script>