import PhoneCall from "./phone-call";
import store from "../../store";
import axios from "axios";
import i18n from "../../i18n";

class OutboundCall extends PhoneCall {
	constructor(callInstance) {
		super(callInstance);
		this.direction = "outbound";
	}

	start = async (hasClient) => {
		await this.loadDetails(hasClient);

		const checkCallStatusInterval = setInterval(() => {
			const status = this.callInstance.status();

			// IVR with twilio number won't ring, the call status is "open" once the call started.
			if (status === "ringing" || status === "open") {
				clearInterval(checkCallStatusInterval);
				store.commit("voice/setIsOnCall", true);
			} else if (status === "closed") {
				clearInterval(checkCallStatusInterval);
				store.commit("alerts/add", {
					type: "error",
					message: i18n.t("error.phone-invalid"),
					timeout: true
				}, { root: true });
				return;
			}
		}, 1000);
	};

	loadDetails = async (hasClient) => {
		try {
			let client = {};

			if	(hasClient) {
				const { data } = await axios.get(`clients/${this.callInstance?.customParameters.get("To")}`);

				client = data;
			}

			this.callDetails = {
				...client,
				phoneNumber: this.callInstance?.customParameters.get("To")
			};
		} catch (err) {
			this.callDetails = {
				phoneNumber: this.callInstance?.customParameters.get("To")
			};
		}

		store.commit("voice/setCallDetails", this.callDetails);
	};

	static from(callInstance) {
		return new OutboundCall(callInstance);
	}
}

export default OutboundCall;
