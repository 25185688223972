import axios from "axios";
import { getField, updateField } from "vuex-map-fields";
import router from "../router";
import useConversation from "../composables/conversation";
import messaging from "../composables/messaging";
import useVoice from "../composables/voice";

const debug = false;

const IDLE_TIMEOUT = debug ? 600000 : 43200000; // 12 hours (10 minutes in debug)

if (debug) {
	console.warn("=================================================");
	console.warn("JWT refresh and idle timers running in debug mode");
	console.warn("Enable verbose in the chrome console to view logs");
	console.warn("=================================================");
	console.warn("Idle timeout:", IDLE_TIMEOUT);
	console.warn("=================================================");
}

const conversationsHook = useConversation();

let idleTimeout;

export default {
	namespaced: true,
	state: () => ({
		jwt: undefined,
		profile: {},
		userStatus: "offline",
		email: undefined,
		logoutTrigger: undefined
	}),
	mutations: {
		updateField,
		clear(state) {
			state.jwt = undefined;
			state.profile = {};
			state.userStatus = "offline";
			sessionStorage.clear();
		},
		setUserProfile(state, user) {
			state.profile = {
				...user,
				initials: `${user.name?.first[0] || ""}${user.name?.last[0] || ""}`,
				fullName: `${user.name?.first || ""} ${user.name?.last || ""}`
			};
		},
		setUserStatus(state, status) {
			if (state.userStatus !== status) {
				axios.put("/auth/status", { status }).then(() =>{
					state.userStatus = status;
				});
			}
		},
		setEmail: (state, payload) => (state.email = payload),
		setLogoutTrigger: (state, payload) => (state.logoutTrigger = payload)
	},
	actions: {
		async setJwt({ state }, jwt) {
			if (debug) console.debug("Set JWT token");
			state.jwt = jwt;
		},
		async endSession({ dispatch }, options = {}) {
			try {
				const { fromAllDevices } = options;

				await axios.post("/auth/logout", {
					revokeAll: !!fromAllDevices
				});
			} catch (err) {
				console.error("Failed to logout user:", err);
			}
		},
		async logout({ dispatch }) {
			await dispatch("endSession");
			await dispatch("showLogin");
		},
		async refreshToken({ state }){
			if (debug) console.debug("Refreshing jwt token");
			const { data } = await axios.post("/auth/refresh");

			state.jwt = data?.jwt;
			return data?.jwt;
		},
		async sessionExpired({ dispatch }){
			try {
				await dispatch("clearAll");
			} catch (err) {
				console.error("Failed to clean up session:", err);
			} finally {
				if (router.currentRoute.name !== "login"){
					router.push({
						name: "login",
						params: { "session-expired": Date.now() }
					});
				}
			}
			
		},
		async showLogin({ dispatch }){
			await dispatch("clearAll");
			if (router.currentRoute.name !== "login"){
				router.go("login");
			}
		},
		async expireSession({ dispatch }) {
			await dispatch("endSession");
			await dispatch("sessionExpired");
		},
		async activity({ dispatch }) {
			if (debug) console.debug("Activity!");
			await dispatch("resetIdleTimeout");
		},
		clearIdleTimeout() {
			if (debug) console.debug("Clearing idle timeout");
			clearTimeout(idleTimeout);
			idleTimeout = undefined;
		},
		async clearTimers({ dispatch }) {
			await dispatch("clearIdleTimeout");
		},
		async resetIdleTimeout({ state, dispatch }) {
			if (debug) console.debug("Reset Idle timeout");
			await dispatch("clearIdleTimeout");

			if (!state.jwt) return;

			idleTimeout = setTimeout(() => {
				dispatch("expireSession");
			}, IDLE_TIMEOUT);

			if (debug) console.debug(`Reset idle timeout to ${IDLE_TIMEOUT}`);
		},
		async clearAll({ dispatch, commit }) {
			await dispatch("clearTimers");
			commit("clear");
			commit("clinic/resetState", null, { root: true });
			commit("consultation/resetState", null, { root: true });
			commit("conversation/setCurrentGroup", "", { root: true });
			
			// Free conversations resources upon explicit logout or session expiry
			messaging.cleanup();
			useVoice.cleanup();
			conversationsHook.cleanup();
		}
	},
	getters: {
		connected: (state) => state.jwt !== undefined,
		profile: (state) => state.profile,
		userStatus: (state) => state.userStatus,
		hasRoleClearance: (state) => {
			return ["devops", "marketing"].includes(state.profile.role);
		},
		isSuperAdmin: (state) => state.profile.role === "super-admin",
		isDevops: (state) => state.profile.role === "devops",
		email: (state) => state.email,
		logoutTrigger: (state) => state.logoutTrigger,
		getField
	}
};
