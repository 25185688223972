<template>
	<svg
		width="28"
		height="28"
		viewBox="0 0 32 33"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M4.00007 24.5001C4 24.414 4 24.3252 4 24.2336V8.76693C4 7.27345 4 6.52616 4.29065 5.95573C4.54631 5.45396 4.95396 5.04631 5.45573 4.79065C6.02616 4.5 6.77345 4.5 8.26693 4.5H23.7336C25.2271 4.5 25.9734 4.5 26.5438 4.79065C27.0456 5.04631 27.454 5.45396 27.7096 5.95573C28 6.5256 28 7.27199 28 8.76255V24.2375C28 24.8841 28 25.3905 27.9763 25.8032M4.00007 24.5001C4.00109 25.8179 4.01783 26.5078 4.29065 27.0432C4.54631 27.545 4.95396 27.954 5.45573 28.2096C6.0256 28.5 6.77199 28.5 8.26255 28.5H23.7381C25.2287 28.5 25.974 28.5 26.5438 28.2096C27.0456 27.954 27.454 27.545 27.7096 27.0432C27.874 26.7206 27.9453 26.3417 27.9763 25.8032M4.00007 24.5001L10.3573 17.0833L10.3592 17.0813C10.923 16.4235 11.2054 16.094 11.5404 15.9753C11.8346 15.871 12.1558 15.8734 12.4486 15.9818C12.7822 16.1053 13.0601 16.4385 13.6159 17.1055L17.1774 21.3793C17.6919 21.9967 17.9507 22.3072 18.2648 22.4318C18.5419 22.5418 18.8475 22.5541 19.1335 22.4698C19.4589 22.3737 19.7454 22.0873 20.3184 21.5143L20.9811 20.8516C21.5644 20.2682 21.8561 19.9768 22.1862 19.8815C22.4762 19.7978 22.7862 19.8158 23.0651 19.931C23.3827 20.0621 23.6403 20.3832 24.1556 21.0273L27.9763 25.8032M27.9763 25.8032L28 25.8328M20 12.5C19.2636 12.5 18.6667 11.903 18.6667 11.1667C18.6667 10.4303 19.2636 9.83333 20 9.83333C20.7364 9.83333 21.3333 10.4303 21.3333 11.1667C21.3333 11.903 20.7364 12.5 20 12.5Z"
			stroke="#123873"
			stroke-width="2"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
	</svg>
</template>
<script>
export default {
	name:"ImageIcon"
};
</script>