import store from "@/store";
import notificationSound from "@/assets/audio/notification.mp3";
import Vue from "vue";

export const INITIALIZING = "initializing";
export const INITIALIZATION_FAILED = "initialization-failed";
export const CONNECTION_FAILED = "connection-failed";
export const CONNECTING = "connecting";
export const CONNECTED = "connected";
export const DISCONNECTING = "disconnecting";
export const DISCONNECTED = "disconnected";
export const RECONNECTING = "reconnecting";

const audio = new Audio(notificationSound);

export const playAudio = () => {
	if (store.state.conversation.audioNotificationsEnabled) {
		audio.play().catch(() => {});
	}
};

export const toggleState = (state) => {
	store.commit("conversation/setConnectionState", state);

	if (state == INITIALIZING || state == CONNECTING || state == RECONNECTING) {
		store.commit("conversation/setIsInitializing", true);
	} else {
		store.commit("conversation/setIsInitializing", false);
	}
};

export const desktopNotification = () => {
	if (store.state.conversation.desktopNotificationsEnabled && document.hidden) {
		Vue.notification.show("Vous avez une nouvelle notification dans inservio!", {
			body: "",
			silent: true
		}, {});
	}
};
